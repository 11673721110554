import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ChildClient, DeepPartial } from '@models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ClientManagementService {
  constructor(private http: HttpClient) {}

  getClientAccessList(): Observable<ChildClient[]> {
    return this.http.get<{ data: ChildClient[] }>(`user/clientAccessList`).pipe(map(data => data.data));
  }
  getAllChildClient(clientId: string): Observable<ChildClient[]> {
    return this.http
      .get<{ data: ChildClient[] }>(`clientManagement/client/${clientId}/childClients`)
      .pipe(map(data => data.data));
  }

  createChildClient(clientId: string, data: DeepPartial<ChildClient>): Observable<ChildClient> {
    return this.http
      .post<{ data: ChildClient }>(`clientManagement/client/${clientId}/childClient`, data)
      .pipe(map(data => data.data));
  }

  updateChildClient(clientId: string, clientClientId: string, data: DeepPartial<ChildClient>): Observable<ChildClient> {
    return this.http
      .patch<{
        data: ChildClient;
      }>(`clientManagement/client/${clientId}/childClient/${clientClientId}`, data)
      .pipe(map(data => data.data));
  }
}
