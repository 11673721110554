import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus, ExtendedDeviceData } from '@models';

@Pipe({
  name: 'filterDeviceByStatus',
})
export class FilterDeviceByStatusPipe implements PipeTransform {
  transform(items: ExtendedDeviceData[], status: 'current' | 'archived') {
    if (!items) {
      return [];
    }
    if (!status) {
      return items;
    }

    return items.filter(space => {
      return status === 'archived' ? space.status === EntityStatus.Archived : space.status !== EntityStatus.Archived;
    });
  }
}
