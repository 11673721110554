<div
  class="test-skeleton-animation text-center"
  *ngIf="(isFullLocationLoaded$ | async) === false && !locationFullData; else roomDetails">
  <app-skeleton-page-header />
  <ngx-skeleton-loader [theme]="{ height: '130px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  <app-skeleton-table [columnCount]="5" />
  <app-skeleton-table [columnCount]="5" />
</div>
<ng-template #roomDetails>
  @let isSuperAdmin = (isSuperAdmin$ | async);
  <div class="text-content" *ngIf="locationFullData && currentFloor && currentRoom && currentFloorId && currentRoomId">
    <app-dashboard-location-header
      [roomId]="currentRoomId"
      [floorId]="currentFloorId"
      [locationData]="locationFullData" />

    <app-building-info
      [currentFloorId]="currentFloorId"
      [currentRoomId]="currentRoomId"
      (spaceChanged)="spaceChanged($event)"
      [locationData]="locationFullData"
      [floorName]="currentFloor.friendlyName"
      [buildingStats]="buildingStats" />

    <mat-card class="card my-4 py-2">
      <div class="flex items-center justify-between">
        <mat-slide-toggle
          class="main-toggle is-header-toggle"
          labelPosition="before"
          [checked]="showPlan"
          (change)="togglePlan($event)">
          <mat-card-title class="card-title">Block Diagram / Floor Plan</mat-card-title> Show Block Diagram / Floor Plan
        </mat-slide-toggle>
      </div>

      <div [ngClass]="{ 'is-hidden-tab': !showPlan, 'mb-2': showPlan }">
        <div class="my-4">
          <mat-button-toggle-group [(ngModel)]="planType" class="main-toggle-group">
            <mat-button-toggle value="room"> Block Diagram </mat-button-toggle>
            <mat-button-toggle value="floor"> Floor Plan </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div [ngClass]="{ 'is-hidden-tab': planType === 'floor' }">
          <hr class="mb-4 border-ui-gray-light" />
          <app-spaceplan-tabs
            *ngIf="currentRoom && currentLocationId"
            [locationId]="currentLocationId"
            [room]="currentRoom"
            [popover]="devicePopover"
            [canEdit]="false" />
        </div>

        <div [ngClass]="{ 'is-hidden-tab': planType === 'room' }">
          <ng-container *ngIf="!currentFloor.documentReference && currentLocationId">
            @if (isSuperAdmin) {
            <app-upload-spaceplan
              appRestrictedEl
              [locationId]="currentLocationId"
              uploadText="Upload Floor Plan"
              [floorId]="currentFloor.id" />
            } @else {
            <app-no-data noDataText="No floor plan added" />
            }
          </ng-container>
          <app-spaceplan
            *ngIf="currentFloor.documentReference && currentLocationId"
            [currentAttachmentId]="currentRoomId"
            [spaceplan]="{
              id: currentFloor.id,
              documentReference: currentFloor.documentReference,
              attachments: currentFloor.rooms || [],
              parentSpaceId: currentFloor.parentSpaceId
            }"
            [popover]="roomPopover"
            [locationId]="currentLocationId"
            [canEdit]="false" />
        </div>
      </div>
    </mat-card>

    <mat-card class="card pb-2 mb-4">
      <mat-card-title class="card-title">Room Incidents</mat-card-title>
      <mat-card-content class="p-0">
        <app-incidents-table-desktop-filters
          tableId="room-details-incidents-table"
          [filtersEnabled]="true"
          [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
        <app-incidents-table
          tableId="room-details-incidents-table"
          [isInjected]="true"
          [dataIsLoading]="resolvedIncidentsLoading"
          noDataText="All the Room devices are working properly" />
      </mat-card-content>
    </mat-card>

    <mat-card class="card pb-2">
      <mat-card-title class="card-title flex items-center justify-between">
        Devices
        <a
          appRestrictedEl
          [routerLink]="['/devices', 'add-device']"
          [queryParams]="{ locationId: locationFullData.id, floorId: currentFloorId, roomId: currentRoomId }"
          class="btn-base border border-ui-gray-light px-2 py-1 text-ui-dark font-normal cursor-pointer">
          <mat-icon matPrefix>add</mat-icon>
          Add Device
        </a>
      </mat-card-title>

      <mat-card-content class="card-content p-0">
        <lib-devices-filters
          [config]="{
            hide:true,
            filtersEnabled : false,
            columnSelectOptions: devicesFilterService.columnOptions,
          }"
          [tableId]="TABLE_ID"
          [value]="{
            columns: devicesFilterService.displayedColumns,
            floor: currentFloorId,
            room: currentRoomId,
          }"
          (filtersChanged)="filtersChanged($event)" />

        <lib-devices-table
          class="overflow-hidden"
          [displayedColumns]="devicesFilterService.displayedColumns"
          [tableId]="TABLE_ID"
          [config]="{
            isFixedHeight: true
          }" />
      </mat-card-content>
    </mat-card>
    <div class="card-separator"></div>
  </div>
</ng-template>
