import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform<T>(value: T[], sortOrder: SortOrder | string = 'asc', sortKey: keyof T, firstValue?: string): T[] {
    const firstValueLowerCase = firstValue?.toLowerCase();
    const stringArray = [...value].sort((a, b) => {
      const aValue = (a[sortKey] + '').toLowerCase();
      const bValue = (b[sortKey] + '').toLowerCase();

      if (firstValueLowerCase) {
        if (firstValueLowerCase === aValue) return -1;
        if (firstValueLowerCase === bValue) return 1;
      }

      return aValue.localeCompare(bValue, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

    return sortOrder === 'asc' ? stringArray : stringArray.reverse();
  }
}
