import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { spaceplanTabSelectOptions } from '@app-lib';
import { DirectivesModule } from '@directives';
import { SpaceplanTabType } from '@models';

@Component({
  selector: 'app-upload-spaceplan',
  templateUrl: './upload-spaceplan.component.html',
  standalone: true,
  imports: [DirectivesModule, MatFormFieldModule, MatSelectModule, NgIf, MatIcon, MatSuffix],
})
export class UploadSpaceplanComponent implements OnChanges {
  @Input() locationId = '';
  @Input() floorId = '';
  @Input() roomId = '';
  @Input() uploadText = 'Upload File';
  @Input() tabEnabled = false;
  @Input() existedTabs: string[] = [];
  tabSelectOptions = spaceplanTabSelectOptions;
  selectedTabValue: SpaceplanTabType | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['existedTabs']) {
      this.tabSelectOptions = this.tabSelectOptions.map(option => {
        const disabled = this.existedTabs.includes(option.value);
        return {
          ...option,
          disabled,
        };
      });
    }
  }

  onFileUploaded() {
    this.selectedTabValue = null;
  }
}
