import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EntityStatus, FloorSpaceModel, LocationFullData, RoomSpaceModel, SpaceStats } from '@models';
import { AppState } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { MAIN_MENU_ANIMATION_DURATION } from '@app-lib';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { menuShrunk } from '@layout';
import { delay } from 'rxjs';

@Component({
  selector: 'app-building-plan-visual-mode',
  templateUrl: './building-plan-visual-mode.component.html',
  styleUrls: ['./building-plan-visual-mode.component.scss'],
})
export class BuildingPlanVisualModeComponent implements OnChanges {
  protected readonly EntityStatus = EntityStatus;
  @Input() locationData!: LocationFullData;
  @Input() buildingState!: SpaceStats;
  floorSearch = '';
  showArchived = false;
  roomFitsNumber!: number;
  floorsWithSortedRooms: { [key: string]: RoomSpaceModel[] } = {};

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateRoomFitsNumber();
  }

  constructor(private elRef: ElementRef<HTMLElement>, store: Store<AppState>) {
    store
      .select(menuShrunk)
      .pipe(takeUntilDestroyed(), delay(MAIN_MENU_ANIMATION_DURATION))
      .subscribe(() => {
        this.calculateRoomFitsNumber();
      });
  }

  calculateRoomFitsNumber() {
    const containerWidth = this.elRef.nativeElement.querySelector('h5')?.offsetWidth;
    const containerMargin = 16;
    const elementMargin = 4;
    const elementWidth = 24;
    const maxRoomCount = this.locationData.floors.reduce((acc, floor) => {
      if (floor.rooms?.length) {
        const filteredRooms = floor.rooms.filter(room => room.status !== EntityStatus.Archived);
        return filteredRooms?.length > acc ? filteredRooms?.length : acc;
      }
      return acc;
    }, 0);
    if (containerWidth) {
      this.roomFitsNumber = Math.round((containerWidth - containerMargin) / (elementWidth + elementMargin));
      this.roomFitsNumber = this.roomFitsNumber > maxRoomCount ? this.roomFitsNumber : maxRoomCount;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locationData']) {
      this.updateRoomStateData();
      this.calculateRoomFitsNumber();
    }
  }

  updateRoomStateData() {
    this.locationData.floors.forEach(floor => {
      this.generateRoomStateData(floor);
    });
  }

  generateRoomStateData(floor: FloorSpaceModel) {
    const sortingRes: { [key: string]: RoomSpaceModel[] } = {
      alert: [],
      inProgress: [],
      good: [],
      paused: [],
    };
    if (floor.rooms) {
      floor.rooms.forEach(room => {
        if (room.status) {
          if (room.status === EntityStatus.Paused) {
            sortingRes['paused'].push(room);
            return;
          }
          if (room.status === EntityStatus.Active && room.incidentCountByStatuses.newCount) {
            sortingRes['alert'].push(room);
            return;
          }
          if (
            room.status === EntityStatus.Active &&
            !room.incidentCountByStatuses.newCount &&
            room.incidentCountByStatuses.inProgressCount
          ) {
            sortingRes['inProgress'].push(room);
            return;
          }

          sortingRes['good'].push(room);
        }
      });
    }

    this.floorsWithSortedRooms[floor.id] = [
      ...sortingRes['alert'],
      ...sortingRes['inProgress'],
      ...sortingRes['good'],
      ...sortingRes['paused'],
    ];
  }

  searchUpdate(searchValue: string) {
    this.floorSearch = searchValue;
  }
}
