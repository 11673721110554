import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnChanges,
  OnDestroy,
  inject,
  Inject,
  input,
  computed,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DeviceAdditionalAttribute, DeviceAttributeType, DeviceChart, DeviceData } from '@models';
import { AppService, DeviceService } from '@services';
import { Observable, of, Subscription } from 'rxjs';
import { createPredefinedValuesChart, createArbitraryValueChart, getDeviceAttributesByType } from '@app-lib';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatCard } from '@angular/material/card';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HighchartsChartModule } from 'highcharts-angular';
import { DirectivesModule } from '@directives';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { AppState, isDeviceUpdating, updateDeviceData } from '@ngrx-store';
import { EnergyConsumptionWidgetComponent } from '@standalone/energy-consumption-widget/energy-consumption-widget.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';

type PeriodFilter = 'day' | 'week' | 'month';
const DAY = 1;
const WEEK = 7;
const MONTH = 30;
export enum PeriodFilterEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

Highcharts.setOptions({
  time: {
    getTimezoneOffset: function (timestamp: number): number {
      return new Date(timestamp).getTimezoneOffset();
    },
  },
});

NoDataToDisplay(Highcharts);

@Component({
  standalone: true,
  selector: 'app-device-stats-chart',
  templateUrl: './device-stats-chart.component.html',
  styleUrls: ['./device-stats-chart.component.scss'],
  imports: [
    MatButtonToggleGroup,
    MatButtonToggle,
    MatIcon,
    MatProgressSpinner,
    MatSuffix,
    MatOption,
    MatSelect,
    MatFormField,
    MatCard,
    NgxSkeletonLoaderModule,
    HighchartsChartModule,
    DirectivesModule,
    NgClass,
    MatSelectTrigger,
    EnergyConsumptionWidgetComponent,
    NoDataComponent,
  ],
})
export class DeviceStatsChartComponent implements OnDestroy, OnChanges {
  device = input.required<DeviceData>();
  isMobile = input.required<boolean>();
  deviceHasAttributes = computed(() => !!this.device()?.additionalAttributes?.length);
  protected readonly PeriodFilterEnum = PeriodFilterEnum;
  private deviceService = inject(DeviceService);
  private appService = inject(AppService);
  private cdr = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);

  periodFilterValue: PeriodFilter = PeriodFilterEnum.DAY;
  Highcharts: typeof Highcharts = Highcharts;
  updateFromInput = false;
  attributeNames: { [key: string]: string } = {};
  deviceChartsSubs$!: Subscription;
  deviceCharts: DeviceChart[] | null = null;
  isLoading = false;
  clientId = this.appService.currentClient;
  chartData: null | Highcharts.Options = null;
  chartReadyToRender = false;
  locationId!: string | undefined;
  selectedChart = '';
  chartOptions: Array<{ value: string; title: string; friendlyName: string; attributeType: string }> = [];
  isChartFirstTimeLoaded = false;
  currentChart: DeviceChart | null = null;
  isEnergyConsumptionChart = false;

  logChartInstance(chart: Highcharts.Chart) {
    this.chartReadyToRender = true;
    this.cdr.detectChanges();
    return chart;
  }

  changePeriod(event: MatButtonToggleChange) {
    this.periodFilterValue = event.value;
    this.loadCharts();
  }

  getDateRange() {
    const now = new Date();
    const end = new Date();

    if (this.periodFilterValue === PeriodFilterEnum.DAY) {
      end.setDate(now.getDate() - DAY);
    }

    if (this.periodFilterValue === PeriodFilterEnum.WEEK) {
      end.setDate(now.getDate() - WEEK);
    }

    if (this.periodFilterValue === PeriodFilterEnum.MONTH) {
      end.setDate(now.getDate() - MONTH);
    }

    return { fromDateTimeUtc: end.toISOString(), toDateTimeUtc: now.toISOString() };
  }

  loadCharts() {
    if (this.device() && this.device()?.additionalAttributes) {
      const points = getDeviceAttributesByType(
        this.device().additionalAttributes || [],
        DeviceAttributeType.REALTIME
      ).map(point => ({ name: point.name }));
      const { fromDateTimeUtc, toDateTimeUtc } = this.getDateRange();
      this.locationId = this.device().location?.id || this.device().locationId;
      if (this.locationId) {
        this.isLoading = true;
        this.deviceChartsSubs$ = this.deviceService
          .getChartsData({
            clientId: this.clientId,
            deviceId: this.device().id,
            data: {
              fromDateTimeUtc,
              toDateTimeUtc,
              points,
            },
          })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: charts => {
              this.isChartFirstTimeLoaded = true;
              this.deviceCharts = null;
              this.chartData = null;
              this.isLoading = false;
              setTimeout(() => {
                this.deviceCharts = charts;
                this.generateChartData();
              }, 0);
            },
            error: () => {
              this.isLoading = false;
            },
          });
      }
    }
  }

  createChartSelectOptions() {
    const realTimePoints = getDeviceAttributesByType(
      this.device()?.additionalAttributes || [],
      DeviceAttributeType.REALTIME
    );
    if (realTimePoints?.length) {
      this.chartOptions = realTimePoints.map((point: DeviceAdditionalAttribute) => {
        this.attributeNames[point.name] = point?.friendlyName;
        return {
          attributeType: point.attributeType,
          value: point.name,
          friendlyName: point.friendlyName,
          title: point.name,
        };
      });
      console.log(this.chartOptions);
      if (!this.selectedChart) {
        const connectedSelect = this.chartOptions.findIndex(opt => opt.value === 'connected');
        this.selectedChart = this.chartOptions[connectedSelect >= 0 ? connectedSelect : 0]?.value;
      }
    }
  }

  chartChange(value: string) {
    this.selectedChart = value;
    this.generateChartData();
  }

  generateChartData() {
    if (this.deviceCharts) {
      const currentChart = this.deviceCharts.find(({ name }) => name === this.selectedChart);

      if (currentChart) {
        this.currentChart = currentChart;
        this.isEnergyConsumptionChart = currentChart.name.includes('Wattage');
        this.chartData = null;
        setTimeout(() => {
          if (!currentChart.unitOFMeasure || !currentChart.unitOFMeasure.isStep) {
            this.chartData = createArbitraryValueChart(currentChart, true);
          }

          if (currentChart.unitOFMeasure?.isStep) {
            this.chartData = createPredefinedValuesChart(currentChart, true);
          }
        }, 0);
      }
    }
  }

  refreshCharts() {
    if (this.isLoading) {
      return;
    }
    this.loadCharts();
  }

  ngOnDestroy() {
    this.deviceChartsSubs$?.unsubscribe();
  }

  ngOnChanges() {
    if (!this.isChartFirstTimeLoaded) {
      this.createChartSelectOptions();
      this.loadCharts();
    }
  }

  openEditDataPointPopup(chart: { value: string; title: string; friendlyName: string }) {
    const dialogRef = this.dialog.open(EditDataPointDialogComponent, {
      data: {
        device: this.device(),
        attribute: chart,
      },
      panelClass: 'app-dialog',
      backdropClass: 'backdrop-modal-panel',
    });

    dialogRef.afterClosed().subscribe(() => this.createChartSelectOptions());
  }
}

@Component({
  selector: 'app-edit-data-point-dialog',
  templateUrl: './edit-data-point-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AsyncPipe,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatProgressSpinner,
    ReactiveFormsModule,
  ],
})
export class EditDataPointDialogComponent implements OnDestroy {
  private dialogRef = inject(MatDialogRef<EditDataPointDialogComponent>);
  private fb = inject(FormBuilder);
  private store = inject(Store<AppState>);
  form: FormGroup;
  isLoading$: Observable<boolean> = of(false);
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      attribute: { value: string; title: string; friendlyName: string; attributeType: string };
      device: DeviceData | undefined;
    }
  ) {
    this.isLoading$ = this.store.select(isDeviceUpdating);
    this.form = this.fb.group({
      friendlyName: [this.data.attribute.friendlyName],
      attributeType: [{ value: this.data.attribute.attributeType, disabled: true }],
      name: [{ value: this.data.attribute.title, disabled: true }],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private getUpdatedAttributes(attributes: DeviceAdditionalAttribute[], index: number): DeviceAdditionalAttribute[] {
    const updatedAttribute = { ...attributes[index], friendlyName: this.form.value.friendlyName };
    return [...attributes.slice(0, index), updatedAttribute, ...attributes.slice(index + 1)];
  }

  private dispatchUpdate(updatedAttributes: DeviceAdditionalAttribute[]): void {
    const { id: deviceId, location } = this.data.device!;
    this.store.dispatch(
      updateDeviceData({
        locationId: location.id,
        deviceId: deviceId,
        data: { additionalAttributes: updatedAttributes },
      })
    );
    this.subscriptions.add(
      this.store.select(isDeviceUpdating).subscribe(updating => {
        if (!updating) {
          this.dialogRef.close();
        }
      })
    );
  }

  saveChanges() {
    const additionalAttributes = this.data.device?.additionalAttributes;
    if (!additionalAttributes) return;
    const attributeIndex = additionalAttributes.findIndex(attr => attr.name === this.data.attribute.title);
    if (attributeIndex === -1) return;
    const updatedAttributes = this.getUpdatedAttributes(additionalAttributes, attributeIndex);
    this.dispatchUpdate(updatedAttributes);
  }
}
