import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { NormalizedDeviceData } from '@models';
import { API_VERSION_CONTEXT_TOKEN } from '@app-lib';
import { Sort } from '@angular/material/sort';
import { AppService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private appService = inject(AppService);
  private http = inject(HttpClient);

  filters() {
    return this.http.get<{ data: Record<string, unknown> }>(
      `clientId/${this.appService.currentClient}/devices/filters`,
      {
        context: new HttpContext().set(API_VERSION_CONTEXT_TOKEN, 'v2'),
      }
    );
  }

  list(params: { filters?: object; limit?: number; offset?: number; query?: string; sort?: Sort }) {
    return this.http.get<{ count: number; data: NormalizedDeviceData[] }>(
      `clientId/${this.appService.currentClient}/devices`,
      {
        params: {
          ...(params.filters && { filters: JSON.stringify(params.filters) }),
          ...(params.limit && { limit: params.limit }),
          ...(params.offset && { offset: params.offset }),
          ...(params.query && { query: params.query }),
          ...(params.sort && { sort: `${params.sort.active},${params.sort.direction}` }),
        },
        context: new HttpContext().set(API_VERSION_CONTEXT_TOKEN, 'v2'),
      }
    );
  }
}
