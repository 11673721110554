import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, clientIdChanged, clientList, login, selectUser, UserState } from '@ngrx-store';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from '@services';
import { UserClient } from '@models';

export abstract class StartSettingsClass {
  loading: Observable<boolean> | undefined;
  isLoggedIn = false;
  previousUrl: string | undefined;
  clientsList: UserClient[] = [];
  user: UserState | undefined;
  constructor(public store: Store<AppState>, public router: Router, public appService: AppService) {
    const userProfile = localStorage.getItem('user');
    if (userProfile) {
      this.store.dispatch(login({ user: JSON.parse(userProfile) }));
    }
    this.store.select(clientList).subscribe(clientList => {
      this.clientsList = clientList || [];
      if (this.clientsList) {
        this.appService.managingClientId = this.clientsList.find(client => client.isManagingClient)?.id || '';
      }
    });
  }

  onNavigationEnd(previousUrl: string) {}

  onActivationEnd(event: ActivationEnd) {}

  onUserSelected(isLoggedIn: boolean, user: UserState) {}

  init() {
    this.store.select(selectUser).subscribe(user => {
      this.isLoggedIn = !!user.id;
      this.user = user;
    });

    this.router.events.subscribe(event => {
      // Don't do any redirects if user is unauthorized
      if (!this.isLoggedIn || !this.user) {
        return;
      }
      this.onUserSelected(this.isLoggedIn, this.user);
      if (event instanceof NavigationStart) {
        // Don't do anything if it is the same URL
        if (event.url === this.previousUrl) return;

        const params = event.url.split('?')[1];
        const clientId = new URLSearchParams(params).get('clientId');

        // Add query param "clientId" if route doesn't contain it
        if (!clientId) {
          this.router.navigateByUrl(
            `${event.url}${event.url.indexOf('?') < 0 ? '?' : '&'}clientId=${this.appService.currentClient}`
          );
          return;
        }

        // Check if provided "clientId" param in URL has appropriate client
        if (clientId !== this.appService.currentClient) {
          const availableClient = this.clientsList.find(({ id }) => id === clientId);
          // Select new current client if it is available
          if (availableClient) {
            this.appService.currentClient = availableClient.id;
            this.store.dispatch(clientIdChanged());
          } else {
            this.router.navigate(['/dashboard'], {
              queryParams: { clientId: this.appService.currentClient },
              queryParamsHandling: 'merge',
            });
          }
        }
      }

      if (event instanceof NavigationEnd) {
        this.onNavigationEnd(event.url);
      }
      if (event instanceof ActivationEnd) {
        this.onActivationEnd(event);
      }
    });
  }
}
