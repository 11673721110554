@let areRoomDocumentsLoading = areRoomDocumentsLoading$ | async; @let isRoomplanLoading = isRoomplanLoading$ | async;
@let devices = (deviceList$ | async); @let selectedTab = selectedDocumentTabValue(); @if(areRoomDocumentsLoading) {
<ngx-skeleton-loader [theme]="{ height: '400px' }" />
} @else if (room && room.parentSpaceId) {
<div class="mb-2 flex justify-between items-center">
  <div class="flex items-center">
    <mat-spinner *ngIf="isRoomplanLoading" diameter="40" class="mr-1"></mat-spinner>
    <mat-button-toggle-group [value]="selectedTab" (change)="documentTabChange($event)" class="main-toggle-group">
      @for (tab of documentTabs; track tab; let index = $index) {
      <mat-button-toggle [value]="tab">
        <span class="capitalize">{{ tab }}</span>
      </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>
  <button class="btn-white-outlined btn-medium" (click)="addNewTabClick()" appRestrictedEl>
    <mat-icon matIconPrefix>add</mat-icon>
    <span>Add new tab</span>
  </button>
</div>

@if(documentTabs.length === 0) {
<app-no-data noDataText="No block diagram added" />
} @else { @for (document of documents; track document.tab) {
<app-spaceplan
  [hidden]="!(devices && document.tab === selectedTab)"
  [spaceplan]="{
    id: room.id,
    documentReference: document.fileName,
    attachments: devices || [],
    parentSpaceId: room.parentSpaceId
  }"
  [documentTab]="selectedTab"
  [locationId]="locationId"
  [canEdit]="canEdit"
  [popover]="popover" />
} }
<app-upload-spaceplan
  [hidden]="selectedTab !== defaultTab"
  [existedTabs]="documentTabs"
  uploadText="Upload Block Diagram"
  [locationId]="locationId"
  [floorId]="room.parentSpaceId"
  [roomId]="room.id"
  [tabEnabled]="true" />
}
