import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService, ClientManagementService, NotificationsService } from '@services';
import { Router } from '@angular/router';
import { map, of, tap } from 'rxjs';
import { ClientManagementActions } from '../action-types';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { superAdminClientCreated } from '../user';
import { CustomHTTPResponseError } from '@models';

@Injectable()
export class ClientsManagementsEffects {
  loadClientList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientManagementActions.loadChildClients),
      switchMap(() =>
        this.clientManagementService.getClientAccessList().pipe(
          map(clientList => ClientManagementActions.loadChildClientsSuccess({ clientList })),
          catchError(error => of(ClientManagementActions.clientManagementError({ error })))
        )
      )
    )
  );

  addNewClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientManagementActions.addChildClient),
      switchMap(action =>
        this.clientManagementService.createChildClient(this.appService.managingClientId, action.newUserData).pipe(
          map(client => ClientManagementActions.addChildClientSuccess({ newClient: client })),
          catchError(error => of(ClientManagementActions.clientManagementError({ error })))
        )
      )
    )
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientManagementActions.updateChildClient),
      switchMap(action =>
        this.clientManagementService
          .updateChildClient(this.appService.managingClientId, action.clientClientId, action.clientData)
          .pipe(
            map(client => ClientManagementActions.updateChildClientSuccess({ childClient: client })),
            catchError(error => of(ClientManagementActions.clientManagementError({ error })))
          )
      )
    )
  );

  updateClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientManagementActions.updateChildClientSuccess),
        tap(action =>
          this.notificationService.showSuccessMessage(
            `Client ${action.childClient.friendlyName} is successfully updated`
          )
        )
      ),
    { dispatch: false }
  );

  addNewClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientManagementActions.addChildClientSuccess),
        tap(client => {
          this.store.dispatch(
            superAdminClientCreated({
              newClient: {
                id: client.newClient.id,
                roleId: '',
                clientName: client.newClient.clientName,
                clientShortName: client.newClient.shortName,
                friendlyName: client.newClient.friendlyName,
                description: client.newClient.description || '',
                isManagingClient: client.newClient.isManagingClient,
              },
            })
          );
          this.router.navigate(['client-management']);
          this.notificationService.showSuccessMessage(
            `The client ${client.newClient.friendlyName} was successfully  created`
          );
        })
      ),
    { dispatch: false }
  );

  clientManagementError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientManagementActions.clientManagementError),
        tap((error: { error: CustomHTTPResponseError }) => {
          this.notificationService.showErrorMessage('Error:' + error.error.status);
          this.router.navigate(['client-management']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private clientManagementService: ClientManagementService,
    private appService: AppService,
    private notificationService: NotificationsService
  ) {}
}
