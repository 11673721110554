@let myClient = myClient$ | async;
<div class="flex items-center justify-between" [ngClass]="{ 'mb-4': showHeader || showBreadcrumbs || form.valid }">
  <div>
    <app-page-header *ngIf="showHeader"> {{ !device ? 'Add' : 'Edit' }} Device</app-page-header>
    <app-breadcrumbs *ngIf="showBreadcrumbs" [routeNames]="[!device ? 'Add device' : 'Edit device']"></app-breadcrumbs>
  </div>
  <button
    class="test-btn-save btn-green btn-medium"
    (click)="submit()"
    *ngIf="device ? !form.pristine && form.valid : form.valid"
    [disabled]="(isDeviceCreating$ | async) === true || (isDeviceUpdating$ | async) === true">
    <mat-spinner
      matSuffix
      *ngIf="(isDeviceCreating$ | async) === true || (isDeviceUpdating$ | async) === true"
      class="mr-1"
      diameter="20"></mat-spinner>
    Save
  </button>
</div>
<mat-card class="card">
  <mat-card-title class="card-title-small pb-4">{{ cardTitle }}</mat-card-title>
  <mat-card-content class="p-0">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="flex flex-row">
        <label class="input-main-label">Device name:*</label>
        <mat-form-field class="input-classic">
          <input formControlName="deviceName" matInput placeholder="Enter device name" type="text" />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row" formGroupName="tags">
        <label class="input-main-label">Description:</label>
        <mat-form-field class="input-classic">
          <input formControlName="description" matInput placeholder="Enter description" type="text" />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row">
        <label class="input-main-label">Serial №:*</label>
        <mat-form-field class="input-classic">
          <input formControlName="physicalDeviceId" matInput placeholder="Enter serial №" type="text" />
        </mat-form-field>
      </div>

      <div class="flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Device type:*</label>
        <div>
          <mat-form-field class="select-classic">
            <mat-select
              formControlName="deviceType"
              appNoSpaceSelect
              placeholder="Select device type"
              (closed)="modelFilterValue = ''"
              panelClass="single-select-overlay">
              <button class="btn-small btn-green mb-4 ml-3" (click)="openAddDeviceTypeDialog()">
                <mat-icon>add</mat-icon>
                Add new device type
              </button>
              <div class="mb-1 mx-3">
                <app-search-input
                  [inputValue]="modelFilterValue"
                  placeholder="Search device type"
                  inputWidth="100%"
                  (searchDataEvent)="modelSearch($event)"></app-search-input>
              </div>
              <mat-option
                *ngFor="
                  let deviceType of deviceTypeOptions | filterSelectOptions : modelFilterValue | sort : 'asc' : 'title'
                "
                [value]="deviceType.value"
                class="full-width-option">
                <div>
                  {{ deviceType.title }}
                </div>
              </mat-option>
              <!-- Fake options are used here because if there are no options available, select is not opening. -->
              <mat-option *ngFor="let fakeOption of fakeSelectOptions" class="hidden">
                {{ fakeOption.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Manufacturer:*</label>
        <div>
          <mat-form-field class="select-classic">
            <mat-select
              formControlName="manufacturer"
              placeholder="Select manufacturer"
              appNoSpaceSelect
              (closed)="manufacturerFilterValue = ''"
              panelClass="single-select-overlay">
              <button class="btn-small btn-green mb-4 ml-3" (click)="openAddManufacturerDialog()">
                <mat-icon>add</mat-icon>
                Add new manufacturer
              </button>
              <div class="mb-1 mx-3">
                <app-search-input
                  placeholder="Search manufacturer"
                  inputWidth="100%"
                  [inputValue]="manufacturerFilterValue"
                  (searchDataEvent)="manufacturerSearch($event)"></app-search-input>
              </div>
              <mat-option
                *ngFor="
                  let manufacturer of manufacturerOptions
                    | filterSelectOptions : manufacturerFilterValue
                    | sort : 'asc' : 'title'
                "
                [value]="manufacturer.value"
                class="full-width-option">
                <div class="flex justify-between items-center">
                  {{ manufacturer.title }}
                  <i
                    class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                    (click)="editManufacturer($event)"></i>
                </div>
              </mat-option>
              <!-- Fake options are used here because if no options available, select is not opening. -->
              <mat-option *ngFor="let fakeOption of fakeSelectOptions" class="hidden">
                {{ fakeOption.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Model:*</label>
        <div>
          <mat-form-field class="select-classic">
            <mat-select formControlName="model" placeholder="Select model" panelClass="single-select-overlay">
              <button class="btn-small btn-green mb-4 ml-3" (click)="openAddDeviceModelDialog()">
                <mat-icon>add</mat-icon>
                Add new model
              </button>
              <mat-option *ngFor="let model of modelOptions" [value]="model.value">
                {{ model.title }}
              </mat-option>
              <!-- Fake options are used here because if no options available, select is not opening. -->
              <mat-option *ngFor="let fakeOption of fakeSelectOptions" class="hidden">
                {{ fakeOption.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row mb-5" *ngIf="selectedFullModel">
        <label class="input-main-label">Image:</label>
        <div class="classic-form-item-wrapper">
          @if (myClient && selectedFullModel.documentReference) {
          <app-img-loader
            loader="skeleton"
            altText="Device Model"
            errorSrc="assets/icons/image-error.svg"
            [imgSrc]="
              'clientId/' +
              myClient.id +
              '/library/standardMake/' +
              selectedFullModel.make.id +
              '/standardModel/' +
              selectedFullModel.id +
              '/document/' +
              selectedFullModel.documentReference
            " />
          } @else {
          <img alt="Device Image" src="assets/icons/image-placeholder.svg" />
          }
        </div>
      </div>

      <!-- To show dynamic form controls in accordance with the chosen model -->
      <div *ngIf="selectedFullModel" class="test-property-attribute-controls">
        <div class="flex flex-row" formGroupName="tags">
          <label class="input-main-label">Firmware:</label>
          <mat-form-field class="input-classic">
            <input formControlName="firmware" matInput placeholder="Enter firmware" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-row" formGroupName="tags">
          <label class="input-main-label">Ip Address:</label>
          <mat-form-field class="input-classic">
            <input
              formControlName="ipAddress"
              matInput
              placeholder="Enter IP address"
              type="text"
              mask="IP"
              [validation]="false"
              [dropSpecialCharacters]="false" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-row" formGroupName="tags">
          <label class="input-main-label">Hyperlink:</label>
          <div>
            <span
              class="false-form-input"
              *ngIf="form.get('tags.ipAddress')?.value as ipAddress; else ipAddressPlaceholder"
              >http://{{ ipAddress }}/</span
            >
            <mat-form-field class="input-classic w-[246px]">
              <input formControlName="hyperlink" matInput placeholder="Enter hyperlink" type="text" />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row" formGroupName="tags">
          <label class="input-main-label">Mac Address:</label>
          <mat-form-field class="input-classic">
            <input formControlName="macAddress" matInput placeholder="Enter MAC address" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>
      </div>

      @if (dataPointsFormArray) { @let datapointsControls = dataPointsFormArray.controls;
      <div class="flex flex-row test-realtime-attribute-controls" *ngIf="datapointsControls?.length">
        <label class="input-main-label">Data points: </label>
        <ul class="classic-form-item-wrapper flex flex-wrap" formArrayName="dataPoints">
          <li
            *ngFor="let attribute of datapointsControls || []; let i = index"
            [formGroupName]="i"
            class="w-1/2 overflow-hidden overflow-ellipsis">
            @let attrName = attribute.get('name')?.value;
            <mat-checkbox
              class="main-checkbox max-w-full"
              formControlName="isChecked"
              (change)="realtimeAttributeChange(attribute.value, i)"
              >{{ attrName }}
            </mat-checkbox>
          </li>
        </ul>
      </div>
      }
      <input type="submit" hidden="hidden" />
    </form>
  </mat-card-content>
  <mat-card-title class="card-title-small pb-4 mt-6">Device Location</mat-card-title>
  <mat-card-content class="p-0">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Building*</label>
        <div>
          <mat-form-field class="select-classic">
            <mat-select formControlName="building" placeholder="Select Building" panelClass="single-select-overlay">
              <mat-option *ngFor="let building of buildingOptions" [value]="building.value">
                {{ building.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Floor</label>
        <div class="flex">
          <mat-form-field class="select-classic">
            <mat-select formControlName="floor" placeholder="Select Floor" panelClass="single-select-overlay">
              <mat-option *ngFor="let floor of floorOptions" [value]="floor.value">
                {{ floor.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
          <mat-spinner *ngIf="isSpaceListLoading" class="ml-2" diameter="40" color="primary"></mat-spinner>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <label class="input-main-label mt-0">Room</label>
        <div>
          <mat-form-field class="select-classic">
            <mat-select formControlName="room" placeholder="Select Room" panelClass="single-select-overlay">
              <mat-option *ngFor="let room of roomOptions" [value]="room.value">
                {{ room.title }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <input type="submit" hidden="hidden" />
    </form>
  </mat-card-content>
</mat-card>
<ng-template #ipAddressPlaceholder><span class="false-form-input">http://___.___.___.___/</span></ng-template>
