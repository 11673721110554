<app-page-header>
  <div class="leading-10">Reporting</div>
</app-page-header>

<div class="flex items-center justify-between mt-4">
  <mat-button-toggle-group [(ngModel)]="reportingType" class="main-toggle-group" aria-label="type of reports">
    <mat-button-toggle value="incident">Incident</mat-button-toggle>
    <mat-button-toggle value="firmware">Firmware</mat-button-toggle>
  </mat-button-toggle-group>
  <app-export-report-menu
    *ngIf="reportingType === 'firmware'"
    [disabled]="!devicesFilterService.filteredDevices.length"
    (exportDataType)="exportDevices($event)" />
  <app-export-report-menu
    *ngIf="reportingType === 'incident'"
    [disabled]="!incidents?.length"
    (exportDataType)="exportIncidents($event)" />
</div>

<div [hidden]="reportingType !== 'incident'" class="test-incident-report-filters">
  <mat-card class="bg-white card mt-6">
    <mat-card-content class="p-0">
      <app-report-filters
        [reportingType]="reportingType"
        (reportTypeEvent)="reportingType = $event"
        (exportIncidents)="setIncidents($event)" />
    </mat-card-content>
  </mat-card>
</div>

<div [hidden]="!(reportingType === 'incident' && incidents)" class="test-incident-table">
  @if (incidents?.length) {
  <app-incident-charts [incidents]="incidents" />
  } @else {
  <div class="card-separator"></div>
  }
  <mat-card class="card py-0 mb-3">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5 mb-3"
      >Incident List
    </mat-card-title>
    <mat-card-content class="p-0 pb-2">
      <app-incidents-table
        tableId="reporting-incidents-table"
        [incidentsData]="incidents || []"
        [isReportMode]="true"
        [isInjected]="true" />
    </mat-card-content>
  </mat-card>
</div>

<div [hidden]="reportingType !== 'incident'" class="test-default-charts pb-4">
  <div class="grid grid-cols-2 gap-4 my-4 mx-auto">
    <app-incident-manufacturer-chart />
    <app-incidents-monthly-chart />
  </div>
</div>

<mat-card class="card pt-0 mt-6 pb-2 test-firmware-table" [class.hidden]="reportingType !== 'firmware'">
  <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5"
    >Firmware List
  </mat-card-title>

  <mat-card-content class="card-content p-0">
    @if (devicesTable.isReady()) {
    <lib-devices-filters
      [config]="{
          deviceTypeFilter: true,
          filtersEnabled: devicesTable.data().length > 0 || devicesTable.isDataLoading(),
          locationFilter: devicesFilterService.showLocationFilter,
          manufacturerFilter: true,
          reportMode: devicesFilterService.isReportMode,
        }"
      [tableId]="TABLE_ID"
      [value]="{
          columns: devicesFilterService.displayedColumns,
        }"
      (filtersChanged)="filtersChanged($event)" />
    }

    <lib-devices-table
      #devicesTable
      class="overflow-hidden"
      [displayedColumns]="devicesFilterService.displayedColumns"
      [tableId]="TABLE_ID"
      [config]="{
        isNoDataBigSize: true
      }" />
  </mat-card-content>
</mat-card>
