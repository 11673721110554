import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableZoomComponent } from '@standalone/_tables/table-zoom/table-zoom.component';
import { MatTableDataSource } from '@angular/material/table';
import { SessionStorageService } from '@services';
import { DefaultSavedTableSettings } from '@models';

const TYPICAL_COL_HEIGHT = 44;
const TIMEOUT_DURATION_MS = 100;
const NO_ITEMS_HEIGHT = 100;

@Directive({ selector: '[appVirtualTableScrollNormalizer]', standalone: true })
export class VirtualTableScrollNormalizerDirective implements AfterViewInit, OnChanges {
  @Input() tableMinHeight = 0;
  @Input() zoomEnabled = true;
  @Input() changeTrigger!: any;
  @Input() tableId: string | null = null;
  private prevItemsCount!: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewport: CdkVirtualScrollViewport,
    private viewContainerRef: ViewContainerRef,
    private sessionStorageService: SessionStorageService
  ) {}

  ngAfterViewInit(): void {
    this.calculateTableHeight();
    this.addZoomInterface();
    if (!this.tableId) return;
    const tableSavedData = this.sessionStorageService.getItem(this.tableId);
    if (!tableSavedData && this.tableId) {
      this.sessionStorageService.setItem(this.tableId, DefaultSavedTableSettings);
    }
  }

  calculateTableHeight() {
    const tableRows = this.el.nativeElement.querySelectorAll('tbody tr');
    const tableHeader = this.el.nativeElement.querySelector('thead');
    const noDataBlock = this.el.nativeElement.querySelector('app-no-data');
    let tableHeight = tableHeader.offsetHeight;
    const scrollPadding = 20;
    if (tableRows.length > 0) {
      tableRows.forEach((row: HTMLTableRowElement) => {
        tableHeight += row.offsetHeight || TYPICAL_COL_HEIGHT;
      });
      tableHeight += scrollPadding;
    } else {
      tableHeight =
        this.tableMinHeight + tableHeader.offsetHeight + scrollPadding + (noDataBlock?.offsetHeight || NO_ITEMS_HEIGHT);
    }
    this.renderer.setStyle(this.el.nativeElement, 'height', `${tableHeight}px`);
  }

  private recalculateTableHeightAfterDelay(): void {
    setTimeout(() => {
      this.calculateTableHeight();
    }, TIMEOUT_DURATION_MS);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeTrigger']) {
      this.recalculateTableHeightAfterDelay();
      const currentChanges = this.viewport && changes['changeTrigger'].currentValue;
      if (this.viewport) {
        if (currentChanges instanceof MatTableDataSource) {
          if (currentChanges.filteredData.length !== this.prevItemsCount) {
            this.viewport?.scrollToOffset(0, 'auto');
          }
          this.prevItemsCount = currentChanges.filteredData.length;
        } else if (Array.isArray(currentChanges)) {
          if (currentChanges.length !== this.prevItemsCount) {
            this.viewport?.scrollToOffset(0, 'auto');
          }
          this.prevItemsCount = currentChanges.length;
        }
      }
    }
  }

  addZoomInterface() {
    if (this.viewContainerRef && this.zoomEnabled) {
      const componentRef = this.viewContainerRef.createComponent(TableZoomComponent);
      componentRef.instance.tableId = this.tableId;
      Object.assign(componentRef.instance, this.viewport);
    }
  }
}
