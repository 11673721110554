<cdk-virtual-scroll-viewport
  class="max-h-full"
  [class.fixed-scroll]="config().isFixedHeight"
  [itemSize]="TYPICAL_COL_HEIGHT"
  [style.zoom]="zoom()"
  [style.height.px]="tableHeight()">
  @if (isReady()) {
  <table
    class="main-table"
    [class.no-actions-table]="(userRole$ | async) === eUserRolesIds.ReadOnly"
    mat-table
    [dataSource]="dataSource"
    [multiTemplateDataRows]="true"
    matSort
    [matSortActive]="sort()?.active!"
    [matSortDirection]="sort()?.direction!"
    (matSortChange)="sortChanged($event)">
    <!-- Number Column -->
    <ng-container matColumnDef="index" [sticky]="!config().isMobile">
      <th mat-header-cell *matHeaderCellDef>№</th>
      <td mat-cell *matCellDef="let element">
        {{ element._index + 1 }}
      </td>
    </ng-container>

    <!-- Device Name Column -->
    <ng-container matColumnDef="deviceName" [sticky]="!config().isMobile">
      <th
        class="table-border-last-left-sticky"
        [ngClass]="{ 'left-[40px]': !config().isMobile }"
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by device name">
        Device Name
      </th>
      <td
        class="table-border-last-left-sticky"
        [ngClass]="{ 'left-[40px]': !config().isMobile }"
        *matCellDef="let element"
        mat-cell>
        <a class="cursor-pointer" [class.underline]="!config().isReportMode" (click)="goToDevice(element)">
          {{ element.deviceName }}
        </a>
      </td>
    </ng-container>

    <!-- Firmware Column -->
    <ng-container matColumnDef="firmware">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by firmware">Firmware</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.firmware }}
      </td>
    </ng-container>

    <!-- Building Column -->
    <ng-container matColumnDef="building">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by building">Building</th>
      <td mat-cell *matCellDef="let element">
        <a
          [class.underline]="!config().isReportMode"
          [routerLink]="config().isReportMode ? null : ['/dashboard', element.buildingId]">
          {{ element.building || '-' }}
        </a>
      </td>
    </ng-container>

    <!-- Floor Column -->
    <ng-container matColumnDef="floor">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by floor">Floor</th>
      <td *matCellDef="let element" mat-cell>
        @if (config().isReportMode || !element.floorId) {
        {{ element?.floor || '-' }}
        } @else {
        <a class="underline" [routerLink]="['/dashboard', element.buildingId, element.floorId]">
          {{ element?.floor || '-' }}
        </a>
        }
      </td>
    </ng-container>

    <!-- Room Column -->
    <ng-container matColumnDef="room">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by room">Room</th>
      <td *matCellDef="let element" mat-cell>
        @if (config().isReportMode || !element.roomId) {
        {{ element?.room || '-' }}
        } @else {
        <a class="underline" [routerLink]="['/dashboard', element.buildingId, element.floorId, element.roomId]">
          {{ element?.room || '-' }}
        </a>
        }
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by status">Device Status</th>
      <td [class.is-offline]="element.status === eEntityStatus.Paused" *matCellDef="let element" mat-cell>
        <app-entity-status [status]="element.status" />
      </td>
    </ng-container>

    <!-- Device Type Column -->
    <ng-container matColumnDef="deviceType">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by device type">Device Type</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.deviceType || '-' }}
      </td>
    </ng-container>

    <!-- Manufacturer Column -->
    <ng-container matColumnDef="manufacturer">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by manufacturer">
        Manufacturer
      </th>
      <td *matCellDef="let element" mat-cell>
        {{ element.manufacturer || '-' }}
      </td>
    </ng-container>

    <!-- Model Column -->
    <ng-container matColumnDef="model">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by model">Model</th>
      <td mat-cell *matCellDef="let element">{{ element.model }}</td>
    </ng-container>

    <!-- Serial Number Column -->
    <ng-container matColumnDef="serialNumber">
      <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by serial number">Serial №</th>
      <td *matCellDef="let element" mat-cell>{{ element.serialNumber || '-' }}</td>
    </ng-container>

    <!-- IP address Column -->
    <ng-container matColumnDef="ipAddress">
      <th *matHeaderCellDef mat-header-cell>IP address</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.ipAddress || '-' }}
      </td>
    </ng-container>

    <!-- Incident Column -->
    <ng-container matColumnDef="incidents">
      <th *matHeaderCellDef mat-header-cell [hidden]="filters()?.status?.[0] === 'archived'">Incidents</th>
      <td *matCellDef="let element" mat-cell [hidden]="filters()?.status?.[0] === 'archived'">
        <ng-container *ngIf="element.status === eEntityStatus.Active">
          @if (element.incidentCountByStatuses.newCount>0) {
          <app-incident-status-count
            class="mr-1"
            [status]="eIncidentStatus.IN_QUEUE"
            [amount]="element.incidentCountByStatuses.newCount" />
          } @if(element.incidentCountByStatuses.inProgressCount > 0){
          <app-incident-status-count
            [status]="eIncidentStatus.IN_PROGRESS"
            [amount]="element.incidentCountByStatuses.inProgressCount" />
          }
        </ng-container>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions" [stickyEnd]="!config().isMobile">
      <th
        class="table-border-last-right-sticky"
        [class.hidden]="filters()?.status?.[0] === 'archived' && (userRole$ | async) === eUserRolesIds.ClientAdmin"
        *matHeaderCellDef
        mat-header-cell
        appRestrictedEl
        [showForClientAdmin]="true"></th>
      <td
        class="table-border-last-right-sticky"
        [class.hidden]="filters()?.status?.[0] === 'archived' && (userRole$ | async) === eUserRolesIds.ClientAdmin"
        *matCellDef="let element"
        mat-cell
        appRestrictedEl
        [showForClientAdmin]="true">
        <div class="flex justify-end">
          <i
            class="cursor-pointer text-ui-gray-100 text-3xl icon-edit"
            *ngIf="element.status !== eEntityStatus.Archived"
            appRestrictedEl
            [routerLink]="['/devices', 'edit', element.buildingId, element.id]"
            matTooltip="Edit Device"></i>
          <i
            class="icon-play-16 action-icon"
            *ngIf="element.status === eEntityStatus.Paused"
            appRestrictedEl
            [showForClientAdmin]="true"
            [matTooltip]="'Resume'"
            (click)="resumeDevice(element)"></i>
          <i
            class="icon-pause-16 action-icon"
            *ngIf="element.status === eEntityStatus.Active"
            [matTooltip]="'Pause Device'"
            appRestrictedEl
            [showForClientAdmin]="true"
            (click)="pauseDevice(element)"></i>
          <i
            class="icon-add-to-archive action-icon"
            *ngIf="element.status !== eEntityStatus.Archived"
            matTooltip="Archive"
            appRestrictedEl
            (click)="archiveDevice(element)"></i>
          <i
            class="icon-restore action-icon"
            *ngIf="element.status === eEntityStatus.Archived"
            appRestrictedEl
            (click)="restoreDevice(element)"
            matTooltip="Restore"></i>
          <i
            class="icon-remove action-icon text-4xl"
            *ngIf="element.status === eEntityStatus.Archived"
            appRestrictedEl
            (click)="deleteDevicePermanently(element.id)"
            matTooltip="Delete Device"></i>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns(); sticky: true" mat-header-row></tr>

    <tr
      *matRowDef="let row; columns: []; when: placeholderWhen"
      mat-row
      [style.height.px]="dataSource.offset$ | async"></tr>

    <tr
      [class.is-offline]="row.status === eEntityStatus.Paused"
      *matRowDef="let row; columns: displayedColumns()"
      mat-row></tr>
  </table>

  @if (!data().length && !isDataLoading()) {
  <app-no-data
    [resultContent]="filtersApplied()"
    noDataText="No devices yet"
    [hasBigPadding]="config().isNoDataBigSize || false" />
  } }
</cdk-virtual-scroll-viewport>

@if (isReady()) {
<app-table-zoom [tableId]="tableId()" (zoomChanged)="zoom.set($event)" />
} @else {
<app-skeleton-table />
}
