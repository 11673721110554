import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterById',
})
export class FilterByIdPipe implements PipeTransform {
  transform<T extends { id: string }>(items: T[], id: string): T[] {
    if (!items || !id) {
      return items;
    }

    return items.filter(item => item.id === id);
  }
}
