import { CustomHTTPResponseError, IncidentModel } from '@models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { loadAllIncidentError, loadAllIncidentSuccess, loadAllIncidents, resetIncidents } from './incidents.actions';

export interface IncidentsState extends EntityState<IncidentModel> {
  isLoaded: boolean;
  isLoading: boolean;
  error: CustomHTTPResponseError | null;
}

export const incidentsAdapter = createEntityAdapter<IncidentModel>({});

export const initialIncidentsState: IncidentsState = incidentsAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  error: null,
});

export const incidentsReducer = createReducer(
  initialIncidentsState,
  on(loadAllIncidents, state => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(loadAllIncidentSuccess, (state, action) => {
    return incidentsAdapter.setAll(action.incidents, {
      ...state,
      isLoading: false,
      isLoaded: true,
    });
  }),
  on(loadAllIncidentError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(resetIncidents, () => {
    return initialIncidentsState;
  })
);
