import { Component, inject } from '@angular/core';
import { SpaceDetailsComponent } from '../space-details/space-details.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DevicePopoverComponent } from '@standalone/device-popover/device-popover.component';
import { DeviceTableCols } from '@models';
import { SessionStorageService } from '@services';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
})
export class RoomDetailsComponent extends SpaceDetailsComponent {
  private sessionStorageService = inject(SessionStorageService);
  protected TABLE_ID = 'room-details-device-table';
  protected showPlan = false;
  protected planType: 'room' | 'floor' = 'room';
  protected devicePopover = DevicePopoverComponent;

  protected togglePlan(event: MatSlideToggleChange) {
    this.showPlan = event.checked;
  }

  protected filtersChanged(filters: Record<string, string | string[]>) {
    const columns = filters['columns'];

    if (columns && columns.length !== this.devicesFilterService.displayedColumns.length) {
      this.devicesFilterService.generateTableColumns(filters['columns'] as DeviceTableCols[]);
      this.sessionStorageService.saveColumnsSettingsToSessionStorage(
        this.TABLE_ID,
        this.devicesFilterService.displayedColumns
      );
    }
  }
}
