import {
  DeviceAlertStatus,
  DeviceAttribute,
  DeviceAttributeType,
  DeviceData,
  DeviceFullModel,
  DeviceTableCols,
  EntityStatus,
  SpaceType,
} from '@models';

export const deviceColumnOptions = [
  {
    value: DeviceTableCols.BUILDING,
    title: 'Building',
  },
  {
    value: DeviceTableCols.FLOOR,
    title: 'Floor',
  },
  {
    value: DeviceTableCols.ROOM,
    title: 'Room',
  },
  {
    value: DeviceTableCols.DEVICE_TYPE,
    title: 'Device Type',
  },
  {
    value: DeviceTableCols.MANUFACTURER,
    title: 'Manufacturer',
  },
  {
    value: DeviceTableCols.MODEL,
    title: 'Model',
  },
  {
    value: DeviceTableCols.SERIAL_NUMBER,
    title: 'Serial №',
  },
  {
    value: DeviceTableCols.IP_ADDRESS,
    title: 'IP address',
  },
  {
    value: DeviceTableCols.STATUS,
    title: 'Status',
  },
  {
    value: DeviceTableCols.INCIDENTS,
    title: 'Incidents',
  },
];

export const mockDeviceData: DeviceData[] = [
  {
    id: 'a_string',
    alertStatus: DeviceAlertStatus.IN_ALERT,
    tags: null,
    locationId: 'a_string',
    location: {
      id: 'a_string',
      status: EntityStatus.Active,
      address: {
        streetAddress: 'a_string',
        streetAddress2: 'a_string',
        city: 'a_string',
        stateProvince: 'a_string',
        postalCode: 'a_string',
        country: 'a_string',
      },
      name: 'a_string',
      friendlyName: 'a_string',
      clientFriendlyId: 'a_string',
    },
    additionalAttributes: [],
    spacePath: [
      {
        id: 'a_string',
        name: 'a_string',
        status: EntityStatus.Active,
        friendlyName: 'a_string',
        parentSpaceId: 'a_string',
        type: SpaceType.floor,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
      {
        id: 'a_string',
        name: 'a_string',
        status: EntityStatus.Active,
        friendlyName: 'a_string',
        parentSpaceId: 'a_string',
        type: SpaceType.room,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216162Z',
      },
    ],
    name: 'a_string',
    friendlyName: 'a_string',
    physicalDeviceId: 'a_string',
    integrationDeviceId: 'a_string',
    deviceModelInformation: {
      id: 'a_string',
      name: 'a_string',
      make: {
        id: 'a_string',
        name: 'a_string',
      },
      deviceType: 'a_string',
      documentReference: null,
      ownerClientId: 'a_string',
    },
    status: EntityStatus.Active,
    incidentCountByStatuses: {
      newCount: 1,
      inProgressCount: 1,
    },
    importantDates: {
      provisioningInfo: null,
      installationInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      comissioningInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      decomissionedInfo: null,
    },
    unstructuredDataReference: '',
    createdOn: '2024-05-15T13:35:03.316125Z',
  },
  {
    id: 'b_string',
    alertStatus: DeviceAlertStatus.IN_ALERT,
    tags: null,
    locationId: 'b_string',
    importantDates: {
      provisioningInfo: null,
      installationInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      comissioningInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      decomissionedInfo: null,
    },
    location: {
      id: 'b_string',
      status: EntityStatus.Active,
      address: {
        streetAddress: 'b_string',
        streetAddress2: 'b_string',
        city: 'b_string',
        stateProvince: 'b_string',
        postalCode: 'b_string',
        country: 'b_string',
      },
      name: 'b_string',
      friendlyName: 'b_string',
      clientFriendlyId: 'b_string',
    },
    additionalAttributes: [],
    spacePath: [
      {
        id: 'b_string',
        name: 'b_string',
        status: EntityStatus.Active,
        friendlyName: 'b_string',
        parentSpaceId: 'b_string',
        type: SpaceType.floor,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
      {
        id: 'b_string',
        name: 'b_string',
        status: EntityStatus.Active,
        friendlyName: 'b_string',
        parentSpaceId: 'b_string',
        type: SpaceType.room,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
    ],
    name: 'b_string',
    friendlyName: 'b_string',
    physicalDeviceId: 'b_string',
    integrationDeviceId: 'b_string',
    deviceModelInformation: {
      id: 'b_string',
      name: 'b_string',
      make: {
        id: 'b_string',
        name: 'b_string',
      },
      deviceType: 'b_string',
      documentReference: null,
      ownerClientId: 'b_string',
    },
    status: EntityStatus.Active,
    incidentCountByStatuses: {
      newCount: 1,
      inProgressCount: 1,
    },
    unstructuredDataReference: '',
    createdOn: '2024-05-15T13:35:03.316125Z',
  },
  {
    id: 'c_string',
    alertStatus: DeviceAlertStatus.IN_ALERT,
    tags: null,
    locationId: 'c_string',
    importantDates: {
      provisioningInfo: null,
      installationInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      comissioningInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      decomissionedInfo: null,
    },
    location: {
      id: 'c_string',
      status: EntityStatus.Active,
      address: {
        streetAddress: 'c_string',
        streetAddress2: 'c_string',
        city: 'c_string',
        stateProvince: 'c_string',
        postalCode: 'c_string',
        country: 'c_string',
      },
      name: 'c_string',
      friendlyName: 'c_string',
      clientFriendlyId: 'c_string',
    },
    additionalAttributes: [],
    spacePath: [
      {
        id: 'c_string',
        name: 'c_string',
        status: EntityStatus.Active,
        friendlyName: 'c_string',
        parentSpaceId: 'c_string',
        type: SpaceType.floor,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
      {
        id: 'c_string',
        name: 'c_string',
        status: EntityStatus.Active,
        friendlyName: 'c_string',
        parentSpaceId: 'c_string',
        type: SpaceType.room,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216162Z',
      },
    ],
    name: 'c_string',
    friendlyName: 'c_string',
    physicalDeviceId: 'c_string',
    integrationDeviceId: 'd_string',
    deviceModelInformation: {
      id: 'c_string',
      name: 'c_string',
      make: {
        id: 'c_string',
        name: 'c_string',
      },
      deviceType: 'c_string',
      documentReference: null,
      ownerClientId: 'c_string',
    },
    status: EntityStatus.Active,
    incidentCountByStatuses: {
      newCount: 1,
      inProgressCount: 1,
    },
    unstructuredDataReference: '',
    createdOn: '2024-05-15T13:35:03.316125Z',
  },
  {
    id: 'd_string',
    alertStatus: DeviceAlertStatus.IN_ALERT,
    tags: null,
    locationId: 'd_string',
    importantDates: {
      provisioningInfo: null,
      installationInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      comissioningInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      decomissionedInfo: null,
    },
    location: {
      id: 'd_string',
      status: EntityStatus.Active,
      address: {
        streetAddress: 'd_string',
        streetAddress2: 'd_string',
        city: 'd_string',
        stateProvince: 'd_string',
        postalCode: 'd_string',
        country: 'd_string',
      },
      name: 'd_string',
      friendlyName: 'd_string',
      clientFriendlyId: 'd_string',
    },
    additionalAttributes: [],
    spacePath: [
      {
        id: 'd_string',
        name: 'd_string',
        status: EntityStatus.Active,
        friendlyName: 'd_string',
        parentSpaceId: 'd_string',
        type: SpaceType.floor,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
      {
        id: 'd_string',
        name: 'd_string',
        status: EntityStatus.Active,
        friendlyName: 'd_string',
        parentSpaceId: 'd_string',
        type: SpaceType.room,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216162Z',
      },
    ],
    name: 'd_string',
    friendlyName: 'd_string',
    physicalDeviceId: 'd_string',
    integrationDeviceId: 'd_string',
    deviceModelInformation: {
      id: 'd_string',
      name: 'd_string',
      make: {
        id: 'd_string',
        name: 'd_string',
      },
      deviceType: 'd_string',
      documentReference: null,
      ownerClientId: 'd_string',
    },
    status: EntityStatus.Active,
    incidentCountByStatuses: {
      newCount: 1,
      inProgressCount: 1,
    },
    unstructuredDataReference: '',
    createdOn: '2024-05-15T13:35:03.316125Z',
  },
  {
    id: 'test',
    alertStatus: DeviceAlertStatus.IN_ALERT,
    tags: null,
    locationId: 'loc_id',
    importantDates: {
      provisioningInfo: null,
      installationInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      comissioningInfo: {
        dateTime: '2023-09-13T20:33:39.5802316Z',
      },
      decomissionedInfo: null,
    },
    location: {
      id: 'd_string',
      status: EntityStatus.Paused,
      address: {
        streetAddress: 'd_string',
        streetAddress2: 'd_string',
        city: 'd_string',
        stateProvince: 'd_string',
        postalCode: 'd_string',
        country: 'd_string',
      },
      name: 'd_string',
      friendlyName: 'friendly name',
      clientFriendlyId: 'd_string',
    },
    additionalAttributes: [],
    spacePath: [
      {
        id: 'd_string',
        name: 'd_string',
        status: EntityStatus.Active,
        friendlyName: 'floor name',
        parentSpaceId: 'd_string',
        type: SpaceType.floor,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216161Z',
      },
      {
        id: 'd_string',
        name: 'd_string',
        status: EntityStatus.Active,
        friendlyName: 'room name',
        parentSpaceId: 'd_string',
        type: SpaceType.room,
        documentReference: '',
        unstructuredDataReference: '',
        externalReference: '',
        devicesCount: 0,
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        incidentCountByStatuses: {
          newCount: 2,
          inProgressCount: 0,
        },
        createdOn: '2023-09-13T20:41:19.216162Z',
      },
    ],
    name: 'd_string',
    friendlyName: 'device friendly name',
    physicalDeviceId: 'serial number',
    integrationDeviceId: 'd_string',
    deviceModelInformation: {
      id: 'd_string',
      name: 'd_string',
      make: {
        id: 'd_string',
        name: 'manufacturer',
      },
      deviceType: 'device type',
      documentReference: null,
      ownerClientId: 'd_string',
    },
    status: EntityStatus.Active,
    incidentCountByStatuses: {
      newCount: 1,
      inProgressCount: 1,
    },
    unstructuredDataReference: '',
    createdOn: '2024-05-15T13:35:03.316125Z',
  },
];

export const manufacturerOptionsMock = [
  {
    value: '1',
    title: 'Samsung',
  },
  {
    value: '2',
    title: 'Xiaomi',
  },
  {
    value: '3',
    title: 'Logitech',
  },
  {
    value: '4',
    title: 'Alcatel',
  },
  {
    value: '5',
    title: 'Asus',
  },
];

export const dataPointOptionsMock = [
  {
    value: '1',
    title: 'Data point 1',
  },
  {
    value: '2',
    title: 'Data point 2',
  },
  {
    value: '3',
    title: 'Data point 3',
  },
  {
    value: '4',
    title: 'Data point 4',
  },
  {
    value: '5',
    title: 'Data point 5',
  },
];

export const modelOptionsMock = [
  {
    value: '1',
    title: 'SQT0-01',
  },
  {
    value: '2',
    title: 'SQT0-02',
  },
  {
    value: '3',
    title: 'SQT0-03',
  },
  {
    value: '4',
    title: 'SQT0-04',
  },
  {
    value: '5',
    title: 'SQT0-05',
  },
];

export const deviceFullModelMock: DeviceFullModel = {
  make: {
    id: 'fa365459-b9df-4e42-9f7e-a578d462a9b0',
    name: 'Crestron',
  },
  deviceType: 'Crestron Controllers',
  standardAttributes: [
    {
      clientId: '1',
      attributeType: DeviceAttributeType.PROPERTY,
      friendlyName: '',
      id: '1',
      name: 'ipAddress',
    },
    {
      clientId: '1',
      attributeType: DeviceAttributeType.REALTIME,
      friendlyName: ' ',
      id: '2',
      name: 'connected',
    },
    {
      clientId: '1',
      attributeType: DeviceAttributeType.PROPERTY,
      friendlyName: '',
      id: '3',
      name: 'firmware',
    },
    {
      clientId: '1',
      attributeType: DeviceAttributeType.PROPERTY,
      friendlyName: 'MAC Address',
      id: '4',
      name: 'macAddress',
    },
  ],
  documentReference: null,
  id: '1',
  name: 'CP3N',
  ownerClientId: '1',
};

export const additionalAttributesMock: DeviceAttribute[] = [
  {
    id: '1',
    clientId: '1',
    attributeType: DeviceAttributeType.REALTIME,
    friendlyName: ' ',
    name: 'connected',
  },
  {
    id: '1',
    clientId: '1',
    attributeType: DeviceAttributeType.PROPERTY,
    friendlyName: '',
    name: 'macAddress',
  },
];
