@if (device() && locationId) {
<mat-card class="card my-4 p-3">
  @if (deviceHasAttributes()) {
  <div class="flex items-center justify-between">
    <div>
      <h3 class="card-title-small">
        {{ attributeNames[selectedChart] || selectedChart }}
      </h3>
      @if (attributeNames[selectedChart]) {
      <div class="text-ui-gray-100">{{ selectedChart }}</div>
      }
    </div>
    @if (!isMobile()) {
    <div>
      <button
        class="border rounded-xl w-[40px] h-[40px] text-center text-ui-gray-100"
        (click)="refreshCharts()"
        [disabled]="isLoading">
        @if (isLoading) {
        <mat-spinner matSuffix class="ml-2" diameter="20" color="primary" />
        } @else {
        <mat-icon fontIcon="refreshIcon" class="text-4xl ml-[-3px] mt-[3px]" />
        }
      </button>
    </div>
    }
  </div>
  <div class="test-chart-container">
    <div class="flex flex-wrap items-center justify-between" [class.my-4]="!isMobile()">
      <div class="flex">
        <mat-button-toggle-group
          [value]="periodFilterValue"
          [class]="isMobile() ? 'mobile-main-toggle-group mb-3' : 'main-toggle-group'"
          name="period"
          [disabled]="isLoading"
          (change)="changePeriod($event)"
          aria-label="period of time">
          <mat-button-toggle [value]="PeriodFilterEnum.DAY"> Day</mat-button-toggle>
          <mat-button-toggle [value]="PeriodFilterEnum.WEEK"> Week</mat-button-toggle>
          <mat-button-toggle [value]="PeriodFilterEnum.MONTH"> Month</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <mat-form-field
        class="attribute-selector select-classic bg-white"
        [ngClass]="{ 'w-full mb-3': isMobile(), 'max-w-[232px]': !isMobile() }">
        <mat-select
          [value]="selectedChart"
          [disabled]="isLoading"
          (valueChange)="chartChange($event)"
          panelClass="single-select-overlay">
          <mat-select-trigger>{{ attributeNames[selectedChart] || selectedChart }}</mat-select-trigger>
          @for (chart of chartOptions; track chart) {
          <mat-option [value]="chart.value">
            <div class="flex py-2">
              <div class="attributes">
                <div>
                  {{ chart.friendlyName || chart.title }}
                </div>
                <div>
                  {{ chart.friendlyName ? chart.title : ' ' }}
                </div>
              </div>
              <div
                class="edit-friendly-name"
                appRestrictedEl
                [showForClientAdmin]="true"
                (click)="openEditDataPointPopup(chart)">
                <mat-icon class="m-0">edit</mat-icon>
              </div>
            </div>
          </mat-option>
          }
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>
    </div>
    @if (isEnergyConsumptionChart) {
    <app-energy-consumption-widget [chartData]="currentChart" />
    } @if (chartData) {
    <highcharts-chart
      class="h-[400px] w-full block"
      [class.is-mobile]="isMobile()"
      [Highcharts]="Highcharts"
      [options]="chartData"
      [update]="updateFromInput"
      [oneToOne]="true"
      (chartInstance)="logChartInstance($event)" />
    } @else if (!chartReadyToRender) {
    <ngx-skeleton-loader [theme]="{ height: '400px' }" />
    } @else if (chartReadyToRender) {
    <div class="h-[400px]"></div>
    }
  </div>
  } @else {
  <app-no-data />
  }
</mat-card>
} @else {
<div class="test-skeleton-loading">
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
</div>
}
