import { Pipe, PipeTransform } from '@angular/core';
import { isSpaceplanTabType } from '@app-lib';
import { AttachmentItemModel } from '@models';

@Pipe({
  name: 'filterAttachmentByType',
})
export class FilterAttachmentByTypePipe implements PipeTransform {
  transform(items: AttachmentItemModel[], type: string, editAttachmentId: string | null) {
    const isTabType = isSpaceplanTabType(type);
    if (!items || !type || !isTabType) {
      return items;
    }

    return items.filter(item => {
      const position = item.unstructuredDataReference && JSON.parse(item.unstructuredDataReference);
      return Boolean(position?.coordinates?.[type] || item.id === editAttachmentId);
    });
  }
}
