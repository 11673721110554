import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IncidentColumn } from '@app-lib';
import { DeviceTableCols, IncidentModel, IncidentTabStatusFilter } from '@models';
import { deviceLoaded, selectAllDevices } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { DevicesFilterService, IncidentsFilterService, SessionStorageService } from '@services';
import { Observable } from 'rxjs';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { DevicesTableV2Component } from '@devices';

@Component({
  selector: 'app-reporting-main-page',
  styleUrl: './reporting-main-page.component.scss',
  templateUrl: './reporting-main-page.component.html',
})
export class ReportingMainPageComponent implements OnDestroy {
  @ViewChild(IncidentsTableComponent) incidentsTable!: IncidentsTableComponent;
  incidents: IncidentModel[] | null = null;
  deviceListLoaded$!: Observable<boolean>;

  reportingType: 'incident' | 'firmware' = 'incident';
  incidentDisplayedColumns = [
    IncidentColumn.INDEX,
    IncidentColumn.TICKET_NUMBER,
    IncidentColumn.STATUS,
    IncidentColumn.DEVICE_ID,
    IncidentColumn.DEVICE_TYPE,
    IncidentColumn.BUILDING,
    IncidentColumn.FLOOR,
    IncidentColumn.ROOM,
    IncidentColumn.REPORTED,
    IncidentColumn.FAULT_NAME,
  ];

  firmwareDisplayedColumns = [
    DeviceTableCols.FIRMWARE,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.BUILDING,
    DeviceTableCols.FLOOR,
    DeviceTableCols.ROOM,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
  ];

  protected devicesFilterService = inject(DevicesFilterService);
  @ViewChild(DevicesTableV2Component)
  protected deviceTable!: DevicesTableV2Component;
  protected incidentsFilterService = inject(IncidentsFilterService);
  protected readonly TABLE_ID = 'reporting-device-table';

  private sessionStorageService = inject(SessionStorageService);
  private store = inject(Store);

  constructor() {
    this.store
      .select(selectAllDevices)
      .pipe(takeUntilDestroyed())
      .subscribe(devices => {
        this.devicesFilterService.isReportMode = true;
        this.devicesFilterService.initColumns(this.firmwareDisplayedColumns);
        this.devicesFilterService.initDevices(devices);
      });
    this.deviceListLoaded$ = this.store.select(deviceLoaded);
    this.incidentsFilterService.displayedColumns = this.incidentDisplayedColumns;
    this.incidentsFilterService.incidentTabStatusFilter = IncidentTabStatusFilter.HISTORY;
  }

  ngOnDestroy() {
    this.devicesFilterService.resetState();
    this.incidentsFilterService.resetState();
  }

  exportIncidents(type: 'json' | 'csv') {
    if (type === 'json') {
      this.incidentsTable.exportIncidentsToJSON();
    }
    if (type === 'csv') {
      this.incidentsTable.exportIncidentsToCSV();
    }
  }

  setIncidents(incidents: IncidentModel[] | null) {
    this.incidents = incidents;
  }

  protected exportDevices(format: 'json' | 'csv') {
    return this.deviceTable.export(format);
  }

  protected filtersChanged(filters: Record<string, string | string[]>) {
    const columns = filters['columns'];

    if (columns) {
      this.devicesFilterService.generateTableColumns(filters['columns'] as DeviceTableCols[]);
      this.sessionStorageService.saveColumnsSettingsToSessionStorage(
        this.TABLE_ID,
        this.devicesFilterService.displayedColumns
      );
    }
  }
}
