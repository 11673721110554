import {
  Component,
  DestroyRef,
  inject,
  signal,
  input,
  viewChild,
  computed,
  ChangeDetectionStrategy,
  effect,
  Signal,
  OnInit,
  untracked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material/dialog';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { EntityStatus, IncidentStatus, NormalizedDeviceData, UserRolesIds } from '@models';
import { Store } from '@ngrx/store';
import { updateDeviceData, userRole } from '@ngrx-store';
import { SessionStorageService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { DirectivesModule } from '@directives';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { FixedSizeItemVirtualViewportTableDataSource } from '@layout';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { TableZoomComponent } from '@standalone/_tables/table-zoom/table-zoom.component';
import { exportDataToCSV, exportDataToJSON, prepareDataForDownloading } from '@app-lib';
import { filter, map, Observable } from 'rxjs';

import { DeviceService } from '../../services';
interface Config {
  isFixedHeight?: boolean;
  isReportMode?: boolean;
  isNoDataBigSize?: boolean;
  isMobile?: boolean;
}
interface TableItem extends NormalizedDeviceData {
  _index: number;
}

const TYPICAL_COL_HEIGHT = 44;
const NO_DATA_HEIGHT = 550;
const FIXED_SIZE_MAX_HEIGHT = 480;
const FIXED_SIZE_NO_DATA_HEIGHT = 100;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col',
  },
  imports: [
    CommonModule,
    DirectivesModule,
    EntityStatusComponent,
    IncidentStatusCountComponent,
    MatSortModule,
    MatTableModule,
    MatTooltip,
    NoDataComponent,
    RouterLink,
    ScrollingModule,
    SkeletonTableComponent,
    TableZoomComponent,
  ],
  selector: 'lib-devices-table',
  standalone: true,
  styleUrl: './devices-table.component.scss',
  templateUrl: './devices-table.component.html',
})
export class DevicesTableV2Component implements OnInit {
  config = input<Config>({
    isFixedHeight: false,
    isReportMode: false,
    isNoDataBigSize: false,
    isMobile: false,
  });
  tableId = input.required<string>();
  displayedColumns = input.required<string[]>();

  data = signal<TableItem[]>([]);
  isDataLoading = signal(false);
  isReady = signal<boolean>(false);

  protected count = signal<number>(0);
  protected dataSource = new FixedSizeItemVirtualViewportTableDataSource<TableItem>([]);
  protected destroyRef = inject(DestroyRef);
  protected eEntityStatus = EntityStatus;
  protected eIncidentStatus = IncidentStatus;
  protected eUserRolesIds = UserRolesIds;
  protected filters: Signal<{
    space?: string;
    status?: string[];
  } | undefined>;
  protected filtersApplied = computed(() => {
    const { space, status, ...filters } = this.filters() ?? {};

    return Object.keys(filters).length > 0 || this.query()?.length > 0;
  });
  protected offset = signal<number>(0);
  protected query: Signal<string>;
  protected sort: Signal<Sort | undefined>;
  protected tableHeight = computed(() => {
    if (!this.isReady()) {
      return 0;
    }

    const height = this.data().length ? (this.data().length + 1) * TYPICAL_COL_HEIGHT : 0;

    if (this.config().isFixedHeight) {
      return Math.min(Math.max(height, FIXED_SIZE_NO_DATA_HEIGHT), FIXED_SIZE_MAX_HEIGHT);
    }

    return height || NO_DATA_HEIGHT;
  });
  protected TYPICAL_COL_HEIGHT = TYPICAL_COL_HEIGHT;
  protected userRole$: Observable<UserRolesIds>;
  protected viewport = viewChild(CdkVirtualScrollViewport);
  protected zoom = signal<string | null>(null);

  private deviceService = inject(DeviceService);
  private dialog = inject(MatDialog);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private sessionStorageService = inject(SessionStorageService);
  private store = inject(Store);

  constructor() {
    this.dataSource.itemSize = TYPICAL_COL_HEIGHT;
    this.userRole$ = this.store.select(userRole);

    this.sort = toSignal(
      this.route.queryParams.pipe(
        filter(params => params['sort']),
        map(params => {
          const [active, direction] = params['sort'].split(',') as [string, SortDirection];

          return { active, direction } as Sort;
        })
      )
    ) as Signal<Sort>;

    this.filters = toSignal(
      this.route.queryParams.pipe(
        filter(params => params['filters']),
        map(({ filters }) => JSON.parse(filters)),
      )
    );

    this.query = toSignal(this.route.queryParams.pipe(map(({ query }) => query)));

    effect(() => {
      this.filters();
      untracked(() => {
        this.offset.set(0);
        this.data.set([]);
        this.dataSource.data = [];
      });
    });

    effect(() => {
      const sort = this.sort();
      const filters = this.filters();
      const offset = this.offset();

      untracked(() => this.loadDevices(sort, filters, offset));
    });

    this.dataSource.thresholdReached$
      .pipe(
        filter(() => this.dataSource.data.length !== this.count()),
        takeUntilDestroyed()
      )
      .subscribe(() => this.offset.set(this.dataSource.data.length));
  }

  export(format: 'json' | 'csv') {
    const sort = this.sort();

    this.deviceService
      .list({
        filters: this.filters(),
        query: this.query(),
        sort: sort?.direction ? sort : undefined,
      })
      .subscribe(({ data }) => {
        const fileName = 'devices report';
        const columns = this.displayedColumns()
          .filter(column => column !== 'actions' && column !== 'status')
          .map(column => (column === 'index' ? 'id' : column));
        const reportData = prepareDataForDownloading(columns, data);

        if (format === 'csv') {
          exportDataToCSV(reportData, fileName);
        } else {
          exportDataToJSON(reportData, fileName);
        }
      });
  }

  ngOnInit() {
    this.dataSource.viewport = this.viewport();
  }

  protected archiveDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Add to Archive',
      description: `Are you sure you want to add ${device.deviceName} to archive?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Archived },
    });
  }

  protected deleteDevicePermanently(id: string) {
    // TODO(serhiisol): add implementation later
    console.log(`device with id: ${id} was permanently deleted`);
  }

  protected goToDevice(device: NormalizedDeviceData) {
    if (this.config().isReportMode) {
      return;
    }

    const url = ['/devices', device.buildingId, device.id];

    if (this.tableId) {
      this.sessionStorageService.saveLastSelectedItems(this.tableId(), { title: device.deviceName, url });
    }

    this.router.navigate(url);
  }

  protected pauseDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Pause',
      description: `Are you sure you want to pause ${device.deviceName} ?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Paused },
    });
  }

  protected placeholderWhen(index: number, _: any) {
    return index === 0;
  }

  protected restoreDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Restore',
      description: `Are you sure you want to restore ${device.deviceName} from archive?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  protected resumeDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Resume',
      description: `Are you sure you want to resume ${device.deviceName} ?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  protected sortChanged(sort: Sort) {
    this.offset.set(0);

    this.router.navigate([], {
      queryParams: {
        sort: sort?.direction ? `${sort.active},${sort.direction}` : null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private loadDevices(sort?: Sort, filters?: object, offset = 0) {
    this.isDataLoading.set(true);

    this.deviceService
      .list({
        filters,
        offset,
        query: this.query(),
        sort: sort?.direction ? sort : undefined,
      })
      .subscribe(({ data, count }) => {
        const devices = data.map((device, index) => ({
          ...device,
          _index: offset + index,
        }));

        this.isDataLoading.set(false);
        this.isReady.set(true);
        this.count.set(count);

        if (offset) {
          this.data.set([...this.data(), ...devices]);
        } else {
          this.data.set(devices);
        }

        this.dataSource.data = this.data();
      });
  }

  private openConfirmationDialog({
    title,
    description,
    locationId,
    deviceId,
    data,
  }: {
    title: string;
    description: string;
    locationId: string;
    deviceId: string;
    data: { [_: string]: string };
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation && locationId && deviceId && data) {
          const shouldBeRemoved = !(this.filters()?.status || []).includes(data['status']);
          this.updateStatus(deviceId, data['status'] as EntityStatus);
          if (shouldBeRemoved) {
            this.removeDevice(deviceId);
          }
          this.store.dispatch(updateDeviceData({ locationId, deviceId, data }));
        }
      });
  }

  private removeDevice(deviceId: string) {
    const data = [...this.data()];
    const index = data.findIndex(({ id }) => id === deviceId);

    data.splice(index, 1);

    this.data.set(data);
    this.dataSource.data = this.data();
  }

  private updateStatus(deviceId: string, status: EntityStatus) {
    const data = [...this.data()];
    const index = data.findIndex(({ id }) => id === deviceId);
    const device = { ...data[index], status };

    data.splice(index, 1, device);

    this.data.set(data);
    this.dataSource.data = this.data();
  }
}
