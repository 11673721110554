<mat-card class="test-location-widget card text-ui-dark" *ngIf="device?.location; else noLocation">
  <ul class="flex flex-grow align-middle">
    <li class="test-building-block text-left text-base pr-4">
      <div class="pb-2 flex items-center">
        <i class="icon-ic_building text-3xl mr-3 text-ui-gray-100"></i>{{ device.location.friendlyName }}
      </div>
      <div class="flex items-center" [matTooltip]="address">
        <i class="icon-ic_location text-3xl mr-3 text-ui-gray-100"></i
        ><span class="custom-text-ellipsis text-base flex-1">{{ address }}</span>
      </div>
    </li>
    <li class="test-floor-block">
      <div class="pb-2 text-sm">Floor:</div>
      @if (device.spacePath && device.spacePath[0]) {
      <div
        class="custom-text-ellipsis font-medium text-base overflow-hidden whitespace-nowrap overflow-ellipsis px-1 font-poppins"
        [matTooltip]="device.spacePath[0].friendlyName || ''">
        {{ device.spacePath[0].friendlyName || '-' }}
      </div>
      } @else {
      <div>-</div>
      }
    </li>
    <li class="test-room-block">
      <div class="pb-2 text-sm">Room:</div>
      @if (device.spacePath && device.spacePath[1]) {
      <div
        class="custom-text-ellipsis font-medium text-base overflow-hidden whitespace-nowrap overflow-ellipsis px-1 font-poppins"
        [matTooltip]="device.spacePath[1].friendlyName || ''">
        {{ device.spacePath[1].friendlyName || '-' }}
      </div>
      } @else {
      <div>-</div>
      }
    </li>
    <li class="flex justify-evenly items-center">
      @if (device.spacePath && device.spacePath[0] && !device.spacePath[1]) {
      <a
        class="underline text-sm font-normal"
        [routerLink]="['/dashboard', device.location.id, device.spacePath[0].id]">
        View Floor
      </a>
      } @else if (device.spacePath && device.spacePath[0] && device.spacePath[1]) {
      <a
        class="underline text-sm font-normal"
        [routerLink]="['/dashboard', device.location.id, device.spacePath[0].id, device.spacePath[1].id]">
        View Room
      </a>
      } @else {
      <a class="underline text-sm font-normal" [routerLink]="['/dashboard', device.location.id]"> View Building </a>
      }
    </li>
  </ul>
</mat-card>
<ng-template #noLocation>
  <ngx-skeleton-loader [theme]="{ height: '120px' }" />
</ng-template>
