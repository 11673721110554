import { Injectable, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { defaultSelectOption, incidentColumnOptions, normalizeIncidents, subtractMonths } from '@app-lib';
import {
  IncidentModel,
  IncidentStatus,
  IncidentTabStatusFilter,
  NormalizedIncident,
  SelectExtendedOption,
  SelectOption,
} from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IncidentsFilterService {
  public filtersApplied = signal(false);
  private _filteredIncidents = new BehaviorSubject<NormalizedIncident[]>([]);
  filteredIncidents$: Observable<NormalizedIncident[]> = this._filteredIncidents.asObservable();

  private _incidentTabStatusFilter: IncidentTabStatusFilter = IncidentTabStatusFilter.OPEN;

  private _resolvedDates = new BehaviorSubject<{ startDateTime: string; endDateTime: string }>({
    startDateTime: '',
    endDateTime: '',
  });
  resolvedDates$ = this._resolvedDates.asObservable();
  initialResolvedDateStart: Date | null = null;
  initialResolvedDateEnd: Date | null = null;

  displayedColumns: string[] = ['status'];
  incidentsData: IncidentModel[] = [];
  resolvedIncidentsData: IncidentModel[] = [];

  isBuildingTable = false;
  isFloorTable = false;
  isRoomTable = false;
  isReportMode = false;
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  normalizedIncidents: NormalizedIncident[] = [];
  newIncidentsAmount = 0;
  inProgressIncidentsAmount = 0;
  columnSelect = new FormControl();

  search = '';
  deviceTypeOptions: SelectExtendedOption[] = [];
  assignedUserValue: Array<string> = [];
  statusOptions = [
    { value: IncidentStatus.IN_QUEUE, title: 'In queue', checked: false },
    { value: IncidentStatus.IN_PROGRESS, title: 'In progress', checked: false },
  ];

  buildingOptions: SelectOption[] = [];
  private _selectedBuilding = '';
  floorOptions: SelectOption[] = [];
  private _selectedFloor = '';
  roomOptions: SelectOption[] = [];
  private _selectedRoom = '';

  // resolved incidents filters
  resolvedDateRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  lastRequestedResolvedStart = '';
  lastRequestedResolvedEnd = '';

  resolvedIncidentsDeviceTypeOptions: SelectExtendedOption[] = [];
  resolvedIncidentsBuildingOptions: SelectOption[] = [];
  private _resolvedIncidentsSelectedBuilding = '';
  resolvedIncidentsFloorOptions: SelectOption[] = [];
  private _resolvedIncidentsSelectedFloor = '';
  resolvedIncidentsRoomOptions: SelectOption[] = [];
  private _resolvedIncidentsSelectedRoom = '';
  resolvedFiltersInitialized = false;

  columnOptions = incidentColumnOptions;

  resolvedIncidentsLoading = false;

  initFilterTableType({
    isRoomTable,
    isFloorTable,
    isBuildingTable,
    displayedColumns,
  }: {
    isRoomTable?: boolean;
    isFloorTable?: boolean;
    isBuildingTable?: boolean;
    displayedColumns: string[];
  }) {
    this.displayedColumns = displayedColumns;

    if (isRoomTable) {
      this.isRoomTable = true;
      this.isFloorTable = true;
      this.isBuildingTable = true;
    }

    if (isFloorTable) {
      this.isFloorTable = true;
      this.isBuildingTable = true;
    }

    if (isBuildingTable) {
      this.isBuildingTable = true;
    }

    if (this.isBuildingTable) {
      this.columnOptions = this.columnOptions.filter(column => column.value !== 'building');
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'building');
    }

    if (this.isFloorTable) {
      this.columnOptions = this.columnOptions.filter(column => column.value !== 'floor');
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'floor');
    }

    if (this.isRoomTable) {
      this.columnOptions = this.columnOptions.filter(column => column.value !== 'room');
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'room');
    }

    this.setColumnsValues();
    this.displayedColumns = this.generateTableColumns(this.displayedColumns);
    this.refreshOpenIncidentsTable();
  }

  get incidentTabStatusFilter() {
    return this._incidentTabStatusFilter;
  }

  set incidentTabStatusFilter(value: IncidentTabStatusFilter) {
    this._incidentTabStatusFilter = value;
    this.search = '';

    if (value === 'history') {
      this.initResolvedIncidentsTable();
      this.refreshResolvedIncidentsTable();
    }

    if (value === 'open') {
      this.refreshOpenIncidentsTable();
    }

    this.setColumnsValues();
  }

  get statusFilter() {
    return this.statusOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set statusFilter(filter: string[]) {
    this.statusOptions = this.statusOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterOpenIncidentsTable();
  }

  updateStatusFilter(filter: { value: string; checked: boolean }) {
    this.statusOptions = this.statusOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterOpenIncidentsTable();
  }

  get selectedBuilding() {
    return this._selectedBuilding;
  }

  set selectedBuilding(building: string) {
    this._selectedBuilding = building;
    this.selectedFloor = '';
    this.selectedRoom = '';
    this.floorOptions = this.generateFloorOptions(this.normalizedIncidents, this.selectedBuilding);
    this.filterOpenIncidentsTable();
  }

  get selectedFloor() {
    return this._selectedFloor;
  }

  set selectedFloor(floor: string) {
    this._selectedFloor = floor;
    this.selectedRoom = '';
    this.roomOptions = this.generateRoomOptions(this.normalizedIncidents, this.selectedFloor);
    this.filterOpenIncidentsTable();
  }

  get selectedRoom() {
    return this._selectedRoom;
  }

  set selectedRoom(room: string) {
    this._selectedRoom = room;
    this.filterOpenIncidentsTable();
  }

  initOpenIncidents(openIncidents: IncidentModel[]) {
    this.incidentsData = openIncidents;
    this.refreshOpenIncidentsTable();
  }

  initResolvedIncidents(resolvedIncidents: IncidentModel[]) {
    this.resolvedIncidentsData = resolvedIncidents;
    this.refreshResolvedIncidentsTable();
  }

  refreshOpenIncidentsTable() {
    if (this.incidentTabStatusFilter === IncidentTabStatusFilter.OPEN) {
      this.normalizedIncidents = normalizeIncidents(this.incidentsData);
      this.newIncidentsAmount = this.incidentsData.filter(
        incident => incident.status === IncidentStatus.IN_QUEUE
      ).length;
      this.inProgressIncidentsAmount = this.incidentsData.filter(
        incident => incident.status === IncidentStatus.IN_PROGRESS
      ).length;

      this.collectOpenIncidentsFilterData();
      this.filterOpenIncidentsTable();
    }
  }

  refreshResolvedIncidentsTable() {
    if (this.incidentTabStatusFilter === IncidentTabStatusFilter.HISTORY) {
      this.normalizedIncidents = normalizeIncidents(this.resolvedIncidentsData);
      this.collectResolvedIncidentsFilterData();
      this.filterResolvedIncidentsTable();
    }
  }

  setColumnsValues() {
    if (this.incidentTabStatusFilter === IncidentTabStatusFilter.OPEN) {
      this.columnOptions = this.columnOptions.filter(({ value }) => value !== 'resolved');
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'resolved');
    } else {
      if (!this.columnOptions.find(({ value }) => value === 'resolved')) {
        const resolvedOption = incidentColumnOptions.find(({ value }) => value === 'resolved');
        if (resolvedOption) {
          this.columnOptions = [...this.columnOptions, resolvedOption];
        }
        this.displayedColumns.push('resolved');
      }
    }

    this.columnSelect.setValue(this.displayedColumns);
  }

  ticketNumberSearch($event: string) {
    this.search = $event;
    if (this.incidentTabStatusFilter === IncidentTabStatusFilter.OPEN) {
      this.filterOpenIncidentsTable();
    } else {
      this.filterResolvedIncidentsTable();
    }
  }

  columnsChange(event: MatSelectChange) {
    this.displayedColumns = this.generateTableColumns(event.value);
  }

  generateTableColumns(values: string[] = []) {
    return ['index', 'ticketNumber', ...values];
  }

  clearOpenIncidentsFilters() {
    if (!this.isRoomTable) {
      this.selectedRoom = '';
    }
    if (!this.isFloorTable) {
      this.selectedFloor = '';
    }
    if (!this.isBuildingTable) {
      this.selectedBuilding = '';
    }
    this.deviceTypeValue = [];
    this.assignedUserValue = [];
    this.statusFilter = [];
    this.filterOpenIncidentsTable();
  }

  get deviceTypeValue() {
    return this.deviceTypeOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set deviceTypeValue(filter: string[]) {
    this.deviceTypeOptions = this.deviceTypeOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterOpenIncidentsTable();
  }

  updateDeviceTypeValue(filter: { value: string; checked: boolean }) {
    this.deviceTypeOptions = this.deviceTypeOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterOpenIncidentsTable();
  }

  multiSelectChange(
    name: 'deviceTypeValue' | 'assignedUserValue' | 'statusFilter' | 'resolvedIncidentsDeviceTypeValue',
    value: string[]
  ) {
    this[name] = value;
    if (this.incidentTabStatusFilter === IncidentTabStatusFilter.OPEN) {
      this.filterOpenIncidentsTable();
    } else {
      this.filterResolvedIncidentsTable();
    }
  }

  get selectedFilters(): boolean {
    return Boolean(
      (this.isBuildingTable ? false : this.selectedBuilding) ||
        (this.isFloorTable ? false : this.selectedFloor) ||
        (this.isRoomTable ? false : this.selectedRoom) ||
        this.statusFilter?.length ||
        this.assignedUserValue?.length ||
        this.deviceTypeValue?.length
    );
  }

  filterOpenIncidentsTable() {
    const filteredOpenIncidents = this.normalizedIncidents.filter(incident => {
      const incidentStatusMatch = this.statusFilter.length
        ? this.statusFilter.some(value => incident.status === value)
        : incident.status !== IncidentStatus.RESOLVED;

      const incidentNumberMatch =
        !this.search || incident.ticketNumber.toLowerCase().includes(this.search.toLowerCase());
      const deviceTypeMatch = !this.deviceTypeValue.length || this.deviceTypeValue.includes(incident.deviceType);
      const buildingMatch =
        !this.selectedBuilding || incident.building.toLowerCase().includes(this.selectedBuilding.toLowerCase());
      const floorMatch = !this.selectedFloor || incident.floor.toLowerCase().includes(this.selectedFloor.toLowerCase());
      const roomMatch = !this.selectedRoom || incident.room.toLowerCase().includes(this.selectedRoom.toLowerCase());

      return incidentStatusMatch && incidentNumberMatch && deviceTypeMatch && buildingMatch && floorMatch && roomMatch;
    });
    this.applyFiltersStatus();
    this._filteredIncidents.next(filteredOpenIncidents);
  }

  collectOpenIncidentsFilterData() {
    const deviceTypeOptions: string[] = [];
    const buildingOptions: string[] = [];

    this.normalizedIncidents.forEach(incident => {
      deviceTypeOptions.push(incident.deviceType);
      buildingOptions.push(incident.building);
    });

    this.deviceTypeOptions = [...new Set(deviceTypeOptions)].map(option => ({
      title: option,
      value: option,
      checked: this.deviceTypeValue.includes(option),
    }));
    this.buildingOptions = [defaultSelectOption].concat(
      [...new Set(buildingOptions)].map(option => ({ title: option, value: option }))
    );

    if (this.isBuildingTable && this.buildingOptions.length > 1) {
      this.selectedBuilding = this.buildingOptions[1].value;
      this.floorOptions = this.generateFloorOptions(this.normalizedIncidents, this.selectedBuilding);
    }

    if (this.isFloorTable && this.floorOptions.length > 1) {
      this.selectedFloor = this.floorOptions[1].value;
      this.roomOptions = this.generateRoomOptions(this.normalizedIncidents, this.selectedFloor);
    }

    // verify selected filters to avoid rendering incorrect data
    // this.deviceTypeValue = this.deviceTypeValue.filter(deviceType =>
    //   this.deviceTypeOptions.find(deviceTypeOption => deviceTypeOption.value === deviceType)
    // );

    if (this.selectedBuilding && !this.isBuildingTable) {
      const selectedBuildingOption = this.buildingOptions.find(
        buildingOption => buildingOption.value === this.selectedBuilding
      );

      if (!selectedBuildingOption) {
        this.selectedBuilding = '';
        this.selectedFloor = '';
        this.selectedRoom = '';
      }
    }

    if (this.selectedFloor && !this.isFloorTable) {
      this.floorOptions = this.generateFloorOptions(this.normalizedIncidents, this.selectedBuilding);
      const selectedFloorOption = this.floorOptions.find(floorOption => floorOption.value === this.selectedFloor);

      if (!selectedFloorOption) {
        this.selectedFloor = '';
        this.selectedRoom = '';
      }
    }

    if (this.selectedRoom) {
      this.roomOptions = this.generateRoomOptions(this.normalizedIncidents, this.selectedFloor);
      const selectedRoomOption = this.roomOptions.find(roomOption => roomOption.value === this.selectedRoom);
      if (!selectedRoomOption) {
        this.selectedRoom = '';
      }
    }
  }

  generateFloorOptions(normalizedIncidents: NormalizedIncident[], selectedBuilding: string) {
    const floorOptions: string[] = normalizedIncidents
      .filter(incidentData => incidentData.building === selectedBuilding)
      .map(incidentData => incidentData.floor);

    return [defaultSelectOption].concat(
      [...new Set(floorOptions)].map(floorName => ({ value: floorName, title: floorName }))
    );
  }

  generateRoomOptions(normalizedIncidents: NormalizedIncident[], selectedFloor: string) {
    const roomOptions: string[] = normalizedIncidents
      .filter(incident => incident.floor === selectedFloor)
      .map(incident => incident.room);

    return [defaultSelectOption].concat(
      [...new Set(roomOptions)].map(roomName => ({ value: roomName, title: roomName }))
    );
  }

  dateChange() {
    this.filterOpenIncidentsTable();
  }

  // resolved incidents methods

  get resolvedIncidentsDeviceTypeValue() {
    return this.resolvedIncidentsDeviceTypeOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set resolvedIncidentsDeviceTypeValue(filter: string[]) {
    this.resolvedIncidentsDeviceTypeOptions = this.resolvedIncidentsDeviceTypeOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterResolvedIncidentsTable();
  }

  updateResolvedIncidentsDeviceTypeValue(filter: { value: string; checked: boolean }) {
    this.resolvedIncidentsDeviceTypeOptions = this.resolvedIncidentsDeviceTypeOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterResolvedIncidentsTable();
  }

  get resolvedIncidentsSelectedBuilding() {
    return this._resolvedIncidentsSelectedBuilding;
  }

  set resolvedIncidentsSelectedBuilding(building: string) {
    this._resolvedIncidentsSelectedBuilding = building;
    this.resolvedIncidentsSelectedFloor = '';
    this.resolvedIncidentsSelectedRoom = '';
    this.resolvedIncidentsFloorOptions = this.generateFloorOptions(
      this.normalizedIncidents,
      this.resolvedIncidentsSelectedBuilding
    );
    this.filterResolvedIncidentsTable();
  }

  get resolvedIncidentsSelectedFloor() {
    return this._resolvedIncidentsSelectedFloor;
  }

  set resolvedIncidentsSelectedFloor(floor: string) {
    this._resolvedIncidentsSelectedFloor = floor;
    this.resolvedIncidentsSelectedRoom = '';
    this.resolvedIncidentsRoomOptions = this.generateRoomOptions(
      this.normalizedIncidents,
      this.resolvedIncidentsSelectedFloor
    );
    this.filterResolvedIncidentsTable();
  }

  get resolvedIncidentsSelectedRoom() {
    return this._resolvedIncidentsSelectedRoom;
  }

  set resolvedIncidentsSelectedRoom(room: string) {
    this._resolvedIncidentsSelectedRoom = room;
    this.filterResolvedIncidentsTable();
  }

  initResolvedIncidentsTable() {
    if (!this.resolvedFiltersInitialized) {
      this.resolvedFiltersInitialized = true;

      const dateToday = new Date(new Date().setHours(0, 0, 0, 0));
      // const dateToday = new Date();
      const dateMonthAgo = subtractMonths(dateToday, 1);

      this.resolvedDateRange.controls['start'].setValue(dateMonthAgo);
      this.resolvedDateRange.controls['end'].setValue(dateToday);

      this.initialResolvedDateStart = dateMonthAgo;
      this.initialResolvedDateEnd = dateToday;

      this.resolvedIncidentsRequest();
    }
  }

  collectResolvedIncidentsFilterData() {
    const deviceTypeOptions: string[] = [];
    const buildingOptions: string[] = [];

    this.normalizedIncidents.forEach(incident => {
      deviceTypeOptions.push(incident.deviceType);
      buildingOptions.push(incident.building);
    });

    this.resolvedIncidentsDeviceTypeOptions = [...new Set(deviceTypeOptions)].map(option => ({
      title: option,
      value: option,
      checked: this.resolvedIncidentsDeviceTypeValue.includes(option),
    }));

    this.resolvedIncidentsBuildingOptions = [defaultSelectOption].concat(
      [...new Set(buildingOptions)].map(option => ({ title: option, value: option }))
    );

    if (this.isBuildingTable && this.resolvedIncidentsBuildingOptions.length > 1) {
      this.resolvedIncidentsSelectedBuilding = this.resolvedIncidentsBuildingOptions[1].value;
      this.resolvedIncidentsFloorOptions = this.generateFloorOptions(
        this.normalizedIncidents,
        this.resolvedIncidentsSelectedBuilding
      );
    }

    if (this.isFloorTable && this.resolvedIncidentsFloorOptions.length > 1) {
      this.resolvedIncidentsSelectedFloor = this.resolvedIncidentsFloorOptions[1].value;
      this.resolvedIncidentsRoomOptions = this.generateRoomOptions(
        this.normalizedIncidents,
        this.resolvedIncidentsSelectedFloor
      );
    }

    // verify selected filters to avoid rendering incorrect data
    // this.resolvedIncidentsDeviceTypeValue = this.resolvedIncidentsDeviceTypeValue.filter(deviceType =>
    //   this.resolvedIncidentsDeviceTypeOptions.find(deviceTypeOption => deviceTypeOption.value === deviceType)
    // );

    if (this.resolvedIncidentsSelectedBuilding && !this.isBuildingTable) {
      const selectedBuildingOption = this.resolvedIncidentsBuildingOptions.find(
        buildingOption => buildingOption.value === this.resolvedIncidentsSelectedBuilding
      );

      if (!selectedBuildingOption) {
        this.resolvedIncidentsSelectedBuilding = '';
        this.resolvedIncidentsSelectedFloor = '';
        this.resolvedIncidentsSelectedRoom = '';
      }
    }

    if (this.resolvedIncidentsSelectedFloor && !this.isFloorTable) {
      this.resolvedIncidentsFloorOptions = this.generateFloorOptions(
        this.normalizedIncidents,
        this.resolvedIncidentsSelectedBuilding
      );
      const selectedFloorOption = this.resolvedIncidentsFloorOptions.find(
        floorOption => floorOption.value === this.resolvedIncidentsSelectedFloor
      );

      if (!selectedFloorOption) {
        this.resolvedIncidentsSelectedFloor = '';
        this.resolvedIncidentsSelectedRoom = '';
      }
    }

    if (this.resolvedIncidentsSelectedRoom) {
      this.resolvedIncidentsRoomOptions = this.generateRoomOptions(
        this.normalizedIncidents,
        this.resolvedIncidentsSelectedFloor
      );
      const selectedRoomOption = this.resolvedIncidentsRoomOptions.find(
        roomOption => roomOption.value === this.resolvedIncidentsSelectedRoom
      );
      if (!selectedRoomOption) {
        this.resolvedIncidentsSelectedRoom = '';
      }
    }
  }

  filterResolvedIncidentsTable() {
    const filteredResolvedIncidents = this.normalizedIncidents.filter(incident => {
      const incidentNumberMatch =
        !this.search || incident.ticketNumber.toLowerCase().includes(this.search.toLowerCase());
      const deviceTypeMatch =
        !this.resolvedIncidentsDeviceTypeValue.length ||
        this.resolvedIncidentsDeviceTypeValue.includes(incident.deviceType);
      const siteMatch =
        !this.resolvedIncidentsSelectedBuilding ||
        incident.building.toLowerCase().includes(this.resolvedIncidentsSelectedBuilding.toLowerCase());
      const floorMatch =
        !this.resolvedIncidentsSelectedFloor ||
        incident.floor.toLowerCase().includes(this.resolvedIncidentsSelectedFloor.toLowerCase());
      const roomMatch =
        !this.resolvedIncidentsSelectedRoom ||
        incident.room.toLowerCase().includes(this.resolvedIncidentsSelectedRoom.toLowerCase());
      this.applyFiltersStatus();
      return incidentNumberMatch && deviceTypeMatch && siteMatch && floorMatch && roomMatch;
    });

    this._filteredIncidents.next(filteredResolvedIncidents);
  }

  calendarClose() {
    this.resolvedIncidentsRequest();
  }

  resolvedIncidentsRequest() {
    if (this.resolvedDateRange.valid && this.resolvedDateRange.value.start && this.resolvedDateRange.value.end) {
      const startDate = new Date(this.resolvedDateRange.value.start);
      const endDate = new Date(this.resolvedDateRange.value.end);

      // datepicker returns start of the day, so we need to add one day to include whole day
      endDate.setDate(endDate.getDate() + 1);

      const startDateTime = startDate.toISOString();
      const endDateTime = endDate.toISOString();

      if (this.lastRequestedResolvedStart !== startDateTime || this.lastRequestedResolvedEnd !== endDateTime) {
        this._resolvedDates.next({
          startDateTime,
          endDateTime,
        });

        this.lastRequestedResolvedStart = startDateTime;
        this.lastRequestedResolvedEnd = endDateTime;
      }
    }
  }

  clearResolvedIncidentsFilters() {
    if (!this.isRoomTable) {
      this.resolvedIncidentsSelectedRoom = '';
    }
    if (!this.isFloorTable) {
      this.resolvedIncidentsSelectedFloor = '';
    }
    if (!this.isBuildingTable) {
      this.resolvedIncidentsSelectedBuilding = '';
    }
    this.resolvedIncidentsDeviceTypeValue = [];
    this.filterResolvedIncidentsTable();
  }

  get resolvedIncidentsSelectedFilters(): boolean {
    return Boolean(
      (this.isBuildingTable ? false : this.resolvedIncidentsSelectedBuilding) ||
        (this.isFloorTable ? false : this.resolvedIncidentsSelectedFloor) ||
        (this.isRoomTable ? false : this.resolvedIncidentsSelectedRoom) ||
        this.resolvedIncidentsDeviceTypeValue?.length
    );
  }

  resetResolvedDate() {
    this.resolvedFiltersInitialized = false;
    this.initResolvedIncidentsTable();
  }

  private applyFiltersStatus() {
    this.filtersApplied.set(
      !!(
        this.selectedRoom.length ||
        this.selectedFloor.length ||
        this.selectedBuilding.length ||
        this.deviceTypeValue.length ||
        this.statusFilter.length
      )
    );
  }

  resetState() {
    this._filteredIncidents.next([]);
    this.incidentTabStatusFilter = IncidentTabStatusFilter.OPEN;
    this._resolvedDates.next({ startDateTime: '', endDateTime: '' });
    this.displayedColumns = ['status'];
    this.incidentsData = [];
    this.resolvedIncidentsData = [];
    this.isBuildingTable = false;
    this.isFloorTable = false;
    this.isRoomTable = false;
    this.isReportMode = false;
    this.normalizedIncidents = [];
    this.newIncidentsAmount = 0;
    this.inProgressIncidentsAmount = 0;
    this.search = '';
    this.deviceTypeOptions = [];
    this.deviceTypeValue = [];
    this.assignedUserValue = [];
    this.statusFilter = [];
    this.buildingOptions = [];
    this.selectedBuilding = '';
    this.floorOptions = [];
    this.selectedFloor = '';
    this.roomOptions = [];
    this.selectedRoom = '';

    this.initialResolvedDateStart = null;
    this.initialResolvedDateEnd = null;
    // resolved incidents filters
    this.resolvedDateRange.reset();
    this.lastRequestedResolvedStart = '';
    this.lastRequestedResolvedEnd = '';
    this.resolvedIncidentsDeviceTypeOptions = [];
    this.resolvedIncidentsDeviceTypeValue = [];
    this.resolvedIncidentsBuildingOptions = [];
    this.resolvedIncidentsSelectedBuilding = '';
    this.resolvedIncidentsFloorOptions = [];
    this.resolvedIncidentsSelectedFloor = '';
    this.resolvedIncidentsRoomOptions = [];
    this.resolvedIncidentsSelectedRoom = '';
    this.resolvedFiltersInitialized = false;
    this.columnOptions = incidentColumnOptions;
  }
}
