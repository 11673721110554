import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadChildClients, selectChildClients } from '@ngrx-store';
import { of, switchMap, take } from 'rxjs';

export const clientsManagementsResolver: ResolveFn<boolean> = (_r, _s) => {
  const store = inject(Store);

  return store.select(selectChildClients).pipe(
    take(1),
    switchMap(clientList => {
      if (clientList.length < 2) {
        store.dispatch(loadChildClients());
      }
      return of(true);
    })
  );
};
