<div>
  <div
    class="border-b-ui-gray-light border-b flex items-center mb-3"
    [class]="buildingDataFilterService.showTabs ? 'justify-between' : 'justify-end'">
    <mat-button-toggle-group
      *ngIf="buildingDataFilterService.showTabs"
      class="desktop-toggle-group"
      name="site-status"
      aria-label="Building Status"
      [value]="buildingDataFilterService.buildingStatusFilter"
      (change)="buildingDataFilterService.buildingStatusFilter = $event.value">
      <mat-button-toggle value="current" class="test-building-status-open">
        <span class="item-status-title">Current</span>
      </mat-button-toggle>
      <mat-button-toggle value="archived" class="test-building-status-history">
        <span class="item-status-title">Archived</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="flex items-center h-16">
      <app-search-input placeholder="Building name" (searchDataEvent)="buildingDataFilterService.search = $event" />
    </div>
  </div>

  <div
    class="flex items-start flex-wrap test-filters-panel"
    *ngIf="buildingDataFilterService.buildingStatusFilter !== 'archived'">
    <mat-form-field class="test-status-filter stroked-select-single mr-2 mb-3">
      <mat-label>Status</mat-label>
      <mat-select
        [value]="buildingDataFilterService.statusFilter"
        (valueChange)="buildingDataFilterService.statusFilter = $event"
        panelClass="single-select-overlay">
        <mat-option
          *ngFor="let siteStatus of buildingDataFilterService.siteStatusOptions | sort : 'asc' : 'title' : 'all'"
          [value]="siteStatus.value">
          {{ siteStatus.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <app-multiple-select
      [value]="buildingDataFilterService.incidentFilter"
      [options]="buildingDataFilterService.incidentOptions"
      label="Incidents"
      panelClass="multiselect-alert-overlay"
      selectClass="stroked-select-multiple mb-3"
      class="mr-2 test-incident-status-select-filter"
      (selectDataEvent)="buildingDataFilterService.incidentFilter = $event" />

    <button
      class="font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 test-clear-all-filters"
      mat-button
      (click)="clearAllFilters()"
      *ngIf="selectedFilters">
      Clear All
    </button>
  </div>
</div>
