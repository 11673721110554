import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedDeviceData } from '@models';

@Pipe({
  name: 'filterDeviceByName',
})
export class FilterDeviceByNamePipe implements PipeTransform {
  transform(items: ExtendedDeviceData[], searchText: string): ExtendedDeviceData[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it.friendlyName.toLocaleLowerCase().includes(searchText);
    });
  }
}
