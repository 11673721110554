import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingMainPageComponent } from './containers/reporting-main-page/reporting-main-page.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { HighchartsChartModule } from 'highcharts-angular';
import { IncidentChartsComponent } from './components/incident-charts/incident-charts.component';
import { MatIconModule } from '@angular/material/icon';
import { FirmwareChartsComponent } from './components/firmware-charts/firmware-charts.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReportFiltersComponent } from './components/report-filters/report-filters.component';
import { MatButtonModule } from '@angular/material/button';
import { IncidentsMonthlyChartComponent } from './components/incidents-monthly-chart/incidents-monthly-chart.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { deviceResolver } from '@ngrx-store';
import { PipesModule } from '@pipes';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { ExportReportMenuComponent } from '@standalone/export-report-menu/export-report-menu.component';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IncidentManufacturerChartComponent } from './components/incident-manufacturer-chart/incident-manufacturer-chart.component';
import { DevicesFiltersV2Component, DevicesTableV2Component } from '@devices';

@NgModule({
  declarations: [
    ReportingMainPageComponent,
    ReportFiltersComponent,
    IncidentChartsComponent,
    FirmwareChartsComponent,
    IncidentsMonthlyChartComponent,
    IncidentManufacturerChartComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: ReportingMainPageComponent,
        resolve: { deviceResolver },
      },
    ]),
    MatCardModule,
    HighchartsChartModule,
    MatIconModule,
    MatIconModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    PipesModule,
    SkeletonTableComponent,
    ExportReportMenuComponent,
    MultipleSelectComponent,
    PageHeaderComponent,
    IncidentsTableComponent,
    NgxSkeletonLoaderModule,

    // V2 tables
    DevicesTableV2Component,
    DevicesFiltersV2Component,
  ],
})
export class ReportingModule {}
