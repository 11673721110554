import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, output, signal, Signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EntityStatus } from '@models';
import { PipesModule } from '@pipes';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { TableSearchInputComponent } from '@standalone/_tables/table-search-input/table-search-input.component';
import { SessionStorageService } from '@services';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { clearObject } from '@app-lib';

import { DeviceService } from '../../services';

interface Config {
  filtersEnabled?: boolean;
  reportMode?: boolean;
  columnSelectOptions?: { title: string; value: string }[];
  deviceTypeFilter?: boolean;
  locationFilter?: boolean;
  hide?: boolean;
  manufacturerFilter?: boolean;
}

interface Option {
  title: string;
  value: string;
  parentId?: string;
}

const FILTERS_STORAGE_KEY_PREFIX = 'avs-device-filters-';

@Component({
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatIcon,
    MatSelectModule,
    MatTooltip,
    MultipleSelectComponent,
    PipesModule,
    ReactiveFormsModule,
    TableSearchInputComponent,
  ],
  selector: 'lib-devices-filters',
  standalone: true,
  templateUrl: './devices-filters.component.html',
})
export class DevicesFiltersV2Component {
  config = input.required<Config>();
  filtersChanged = output<Record<string, string | string[]>>();
  tableId = input.required<string>();
  value = input<Record<string, string | string[]>>();

  protected eEntityStatus = EntityStatus;
  protected filtersEnabled: Signal<boolean>;
  protected floorOptions = signal<Option[]>([]);
  protected form: FormGroup<{
    building?: FormControl<string>;
    columns?: FormControl<string[]>;
    deviceType?: FormControl<string[]>;
    floor?: FormControl<string>;
    deviceMaker?: FormControl<string[]>;
    query?: FormControl<string>;
    room?: FormControl<string>;
    status?: FormControl<string>;
  }>;
  protected hasFiltersSelected: Signal<boolean>;
  protected options = signal<Record<string, Option[]>>({});
  protected queryFilterEnabled: Signal<boolean>;
  protected roomOptions = signal<Option[]>([]);

  private deviceService = inject(DeviceService);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private sessionStorageService = inject(SessionStorageService);

  constructor() {
    this.deviceService.filters().subscribe(({ data }) => this.options.set(data as Record<string, Option[]>));

    this.form = this.fb.group({
      building: [],
      columns: [],
      deviceType: [],
      deviceMaker: [],
      query: [],
      status: [EntityStatus.Active],
      floor: [],
      room: [],
    }) as FormGroup;

    const formValueChanges = toSignal(this.form.valueChanges);

    effect(() => {
      const value = formValueChanges();

      if (value) {
        this.filtersChanged.emit(value);
      }
    });

    const filtersValueChanges = toSignal(
      this.form.valueChanges.pipe(
        map(({ columns, query, ...filters }) => filters),
        distinctUntilChanged()
      )
    );

    const queryChanges = toSignal(
      this.form.valueChanges.pipe(
        map(({ query }) => query),
        distinctUntilChanged(),
        debounceTime(500)
      )
    );

    this.hasFiltersSelected = computed(() => {
      const { status, ...filters } = filtersValueChanges() ?? {};

      return Object.values(filters).filter(Boolean).length > 0;
    });

    this.filtersEnabled = computed(() => {
      const formValue = formValueChanges() ?? {};
      const query = queryChanges() ?? formValue.query ?? '';

      if (formValue.status === EntityStatus.Archived) {
        return false;
      }

      return this.hasFiltersSelected() || query.length > 0 || this.config().filtersEnabled!;
    });

    this.queryFilterEnabled = computed(() => filtersValueChanges()?.status !== EntityStatus.Archived);

    effect(() => {
      const defaultValue = this.value();

      if (defaultValue) {
        this.form.patchValue(defaultValue, { emitEvent: false });
      }
    });

    effect(() => {
      const tableId = this.tableId();
      const storageItem =
        this.sessionStorageService.getItem<Record<string, string | string[]>>(FILTERS_STORAGE_KEY_PREFIX + tableId) ??
        {};
      this.form.patchValue(storageItem);
    });

    effect(() => {
      const filters = filtersValueChanges() ?? {};
      const options = this.options();

      untracked(() => {
        this.floorOptions.set(options['spaces']?.filter(option => option.parentId === filters.building) ?? []);
        this.roomOptions.set(options['spaces']?.filter(option => option.parentId === filters.floor) ?? []);
      });
    });

    effect(() => {
      this.value();
      filtersValueChanges();

      this.setQueryParams({
        filters: this.getFilters(),
        query: queryChanges() ?? this.form.value.query,
      });
    });
  }

  protected clearAllFilters() {
    this.form.reset({
      status: EntityStatus.Active,
    });
  }

  protected selectAllColumns() {
    this.form.patchValue({
      columns: this.config().columnSelectOptions?.map(({ value }) => value),
    });
  }

  private getFilters() {
    const { columns, query, building, floor, room, status, ...filters } = this.form.value;

    if (status === EntityStatus.Archived) {
      return JSON.stringify(clearObject({
        location: this.value()?.['building'],
        space: this.value()?.['room'] || this.value()?.['floor'],
        status: [status],
      }));
    }

    return JSON.stringify(clearObject({
      ...filters,
      location: building,
      space: room || floor,
      status: [EntityStatus.Active, EntityStatus.Paused, EntityStatus.Deactivated]
    }));
  }

  private setQueryParams(queryParams: object) {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.sessionStorageService.setItem(FILTERS_STORAGE_KEY_PREFIX + this.tableId(), this.form.value);
  }
}
