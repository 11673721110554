import { createAction, props } from '@ngrx/store';
import {
  ImageDocument,
  LocationBaseModel,
  LocationData,
  LocationFullData,
  EntitySummary,
  Space,
  CustomHTTPResponseError,
  DeepPartial,
  SpaceplanTabType,
} from '@models';

export const loadLocations = createAction(
  '[Location Resolver] Load all client locations',
  props<{ clientId: string }>()
);

export const locationsLoaded = createAction(
  '[Load Location Effect] All client locations loaded',
  props<{ location: LocationData[] }>()
);

export const loadLocationsSummaries = createAction('[Locations] load summaries', props<{ clientId: string }>());
export const loadLocationsSummariesSuccess = createAction(
  '[Locations] load summaries success',
  props<{ data: Array<{ id: string; changes: EntitySummary }> }>()
);

export const loadLocationsSummariesError = createAction('[Locations] load summaries error');

export const addLocation = createAction(
  '[Add Location start] Location create',
  props<{ newLocation: LocationBaseModel; clientId: string }>()
);

export const addLocationSuccess = createAction(
  '[Add Location success] Location created',
  props<{ newLocation: LocationData }>()
);
export const locationError = createAction('[Add Location error]', props<{ locationError: CustomHTTPResponseError }>());
export const loadLocationError = createAction(
  '[Load Location error]',
  props<{ locationError: CustomHTTPResponseError }>()
);

export const updateLocation = createAction(
  '[Update Location start] Location create',
  props<{ locationId: string; data: DeepPartial<LocationData>; clientId: string }>()
);
export const updateLocationSuccess = createAction(
  '[Update Location success] Location created',
  props<{ location: LocationData }>()
);

export const getLocationState = createAction(
  '[Get location state with rooms and floors] receive full location object',
  props<{ locationId: string; clientId: string; excludeArchived: boolean }>()
);

export const getLocationStateSuccess = createAction(
  '[Get location state with rooms and floors success] receive full location object success',
  props<{ locationState: LocationFullData }>()
);

export const resetLocationState = createAction('[Reset location state]');

export const resetDefaultLocationsState = createAction('[Reset default locations state');
// Floor action creators
export const addFloor = createAction('[Floor] add to location', props<{ locationId: string; data: Partial<Space> }>());

export const addFloorSuccess = createAction('[Floor] add to location success', props<{ floor: Space }>());

export const addFloorError = createAction('[Floor] add to location error', props<{ error: CustomHTTPResponseError }>());
// Room action creators
export const addRoom = createAction('[Room] add to floor', props<{ locationId: string; data: Partial<Space> }>());

export const addRoomSuccess = createAction('[Room] add to floor success', props<{ room: Space }>());

export const addRoomError = createAction('[Room] add to floor error', props<{ error: CustomHTTPResponseError }>());

export const updateRoom = createAction(
  '[Room] update',
  props<{ locationId: string; spaceId: string; data: DeepPartial<Space> }>()
);

export const updateRoomSuccess = createAction('[Room] update success', props<{ room: Space }>());

export const updateRoomError = createAction('[Room] update error', props<{ error: CustomHTTPResponseError }>());

export const uploadRoomplan = createAction(
  '[Room] upload document',
  props<{ file: File; locationId: string; floorId: string; roomId: string; tab: SpaceplanTabType }>()
);

export const uploadRoomplanSuccess = createAction(
  '[Room] upload document success',
  props<{ document: ImageDocument; floorId: string; roomId: string }>()
);

export const uploadRoomplanError = createAction(
  '[Room] upload document error',
  props<{ error: CustomHTTPResponseError }>()
);

export const deleteRoomplan = createAction(
  '[Room] delete document',
  props<{ locationId: string; roomId: string; floorId: string; fileName: string }>()
);

export const deleteRoomplanSuccess = createAction(
  '[Room] delete document success',
  props<{ roomId: string; floorId: string; fileName: string }>()
);

export const deleteRoomplanError = createAction(
  '[Room] delete document error',
  props<{ error: CustomHTTPResponseError }>()
);

export const getRoomDocuments = createAction(
  '[Room] get all documents',
  props<{ locationId: string; floorId: string; roomId: string }>()
);

export const getRoomDocumentsSuccess = createAction(
  '[Room] get all documents success',
  props<{ floorId: string; roomId: string; documents: ImageDocument[] }>()
);

export const getRoomDocumentsError = createAction('[Room] get all documents error');

// Add floorplan action creators
export const uploadFloorplan = createAction(
  '[Floorplan] upload',
  props<{ file: File; locationId: string; spaceId: string }>()
);

export const uploadFloorplanSuccess = createAction(
  '[Floorplan] upload success',
  props<{ document: ImageDocument; spaceId: string }>()
);

export const uploadFloorplanError = createAction(
  '[Floorplan] upload error',
  props<{ error: CustomHTTPResponseError }>()
);
// Delete floorplan action creators
export const deleteFloorplan = createAction(
  '[Floorplan] delete',
  props<{ locationId: string; spaceId: string; fileName: string }>()
);

export const deleteFloorplanSuccess = createAction('[Floorplan] delete success', props<{ spaceId: string }>());

export const deleteFloorplanError = createAction(
  '[Floorplan] delete error',
  props<{ error: CustomHTTPResponseError }>()
);
// update floor action creators
export const updateFloor = createAction(
  '[Floor] update',
  props<{ locationId: string; spaceId: string; data: DeepPartial<Space> }>()
);

export const updateFloorSuccess = createAction('[Floor] update success', props<{ floor: Space; locationId: string }>());

export const updateFloorError = createAction('[Floor] update error', props<{ error: CustomHTTPResponseError }>());

export const noOpAction = createAction('[App] no op action');
