import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { COUNTRY_LIST, DEFAULT_COUNTRY, getUserClient } from '@app-lib';
import { UserModel, UserRolesTypes, userRoles } from '@models';
import { AppState, updateNotificationData, updatePersonalData } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { PhoneNumberClass } from '@abstraction';
import { MatFormField, MatOption, MatSelect, MatSelectChange, MatSuffix } from '@angular/material/select';
import { openChangePasswordDialog } from '@standalone/_modals/change-password-dialog/change-password-dialog.component';
import { MatCardTitle } from '@angular/material/card';
import { MainInputFormComponent } from '@standalone/main-input-form/main-input-form.component';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { PipesModule } from '@pipes';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  imports: [
    MatCardTitle,
    MainInputFormComponent,
    NgIf,
    MatSelect,
    MatFormField,
    MatOption,
    PipesModule,
    NgClass,
    MatSlideToggle,
    NgTemplateOutlet,
    MatCheckbox,
    MatIcon,
    MatSuffix,
  ],
})
export class PersonalProfileComponent extends PhoneNumberClass {
  protected readonly DEFAULT_COUNTRY = DEFAULT_COUNTRY;
  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  @Input() user: UserModel | undefined;
  @Input() isMobile = false;
  userRoles = userRoles;
  userRolesTypes = UserRolesTypes;

  constructor(private store: Store<AppState>, private dialog: MatDialog) {
    super();

    const selectedCountry = COUNTRY_LIST.find(
      country => country.code === (this.user?.phone.countryCode || DEFAULT_COUNTRY.code)
    );
    if (selectedCountry) {
      this.phoneMask.set(selectedCountry.mask);
      this.phoneMaskExpression.set(selectedCountry.maskExpression);
    }
  }

  changePassword() {
    openChangePasswordDialog(this.dialog);
  }

  toggleNotification(event: MatSlideToggleChange, type: 'sms' | 'email', user: UserModel) {
    const notificationsPreferences = {
      emailEnabled: type === 'email' ? event.checked : user.notificationsPreferences.emailEnabled,
      smsEnabled: type === 'sms' ? event.checked : user.notificationsPreferences.smsEnabled,
    };
    const clientId = getUserClient(user) || '';
    if (clientId) {
      this.store.dispatch(
        updateNotificationData({
          userId: user.id,
          data: { notificationsPreferences },
        })
      );
    }
  }

  updateUserData(data: Record<string, string | object>, user: UserModel) {
    const clientId = getUserClient(user);
    if (clientId) {
      this.store.dispatch(updatePersonalData({ data }));
    }
  }

  updateCountryCode($event: MatSelectChange, user: UserModel) {
    this.changeMaskSettings($event);
    this.store.dispatch(
      updatePersonalData({
        data: {
          phone: {
            countryCode: $event.value,
            smsOptIn: user.phone.smsOptIn,
            number: user.phone.number,
            countryNumberCode:
              COUNTRY_LIST.find(country => country.code === $event.value)?.phoneCode || DEFAULT_COUNTRY.phoneCode,
          },
        },
      })
    );
  }

  updatePhoneNumber($event: Record<string, string>, user: UserModel) {
    this.store.dispatch(
      updatePersonalData({
        data: {
          phone: {
            smsOptIn: user.phone.smsOptIn,
            countryCode: user.phone.countryCode,
            number: Object.values($event)[0],
            countryNumberCode:
              COUNTRY_LIST.find(country => country.code === user.phone.countryCode)?.phoneCode ||
              DEFAULT_COUNTRY.phoneCode,
          },
        },
      })
    );
  }

  updateSMSOptIn(value: boolean, user: UserModel) {
    this.store.dispatch(
      updatePersonalData({
        data: {
          phone: {
            smsOptIn: value,
            countryCode: user.phone.countryCode,
            number: user.phone.number,
            countryNumberCode:
              COUNTRY_LIST.find(country => country.code === user.phone.countryCode)?.phoneCode ||
              DEFAULT_COUNTRY.phoneCode,
          },
        },
      })
    );
  }
}
