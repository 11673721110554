<div class="flex justify-between items-center mb-4">
  <app-page-header>
    <div class="leading-10">Devices</div>
  </app-page-header>
  <div class="flex">
    <a appRestrictedEl class="btn-white-outlined btn-medium mr-3" routerLink="/devices/device-management">
      <i class="icon-settings text-3xl mr-2"></i>
      <span class="text-ui-dark">Device Management</span>
    </a>
    <app-export-report-menu class="mr-3" (exportDataType)="exportDevices($event)" />
    <a appRestrictedEl class="btn-yellow btn-medium" routerLink="/devices/add-device">
      <mat-icon>add</mat-icon>
      Add Device
    </a>
  </div>
</div>

<mat-card class="card pt-0 pb-2">
  <mat-card-content class="card-content p-0">
    @if (devicesTable.isReady()) {
    <lib-devices-filters
      [config]="{
          columnSelectOptions: devicesFilterService.columnOptions,
          deviceTypeFilter: true,
          filtersEnabled: devicesTable.data().length > 0 || devicesTable.isDataLoading(),
          locationFilter: devicesFilterService.showLocationFilter,
          manufacturerFilter: true,
          reportMode: devicesFilterService.isReportMode,
        }"
      [tableId]="TABLE_ID"
      [value]="{
          columns: devicesFilterService.displayedColumns,
        }"
      (filtersChanged)="filtersChanged($event)" />
    }

    <lib-devices-table
      #devicesTable
      class="overflow-hidden"
      [displayedColumns]="devicesFilterService.displayedColumns"
      [tableId]="TABLE_ID"
      [config]="{
        isNoDataBigSize: true
      }" />
  </mat-card-content>
</mat-card>
