import { createAction, props } from '@ngrx/store';
import { UserState } from './auth.reducers';
import { ChangePassword, ResetPassword, UserClient, UserModel } from '@models';

export const login = createAction('[Login Page] User Login', props<{ user: UserState }>());

export const logout = createAction('[Top Menu] Logout');

export const passwordRecovery = createAction(
  '[user password recovery] Sending recovery password data',
  props<{ recoveryData: ResetPassword }>()
);

export const passwordRecovered = createAction('[user password recovery success] Password recovered');

export const forgotPassword = createAction(
  '[user forgot password] Sending forgot password data',
  props<{ email: string }>()
);

export const forgotPasswordSuccess = createAction('[user forgot password success] Instructions sent on Email');

export const authActionError = createAction('[Auth state error response] error object sent', props<{ error: Error }>());

export const authChangeUserPassword = createAction(
  '[Auth user password change req sent] Change password start',
  props<{ data: ChangePassword }>()
);
export const authUserPasswordChanged = createAction('[Auth user password changed] Password changed');

export const updatePersonalData = createAction('[User] update personal data', props<{ data: Partial<UserModel> }>());

export const updateNotificationData = createAction(
  '[User] update notification data',
  props<{ userId: string; data: Partial<UserModel> }>()
);

export const updatePersonalDataSuccess = createAction(
  '[User] update personal data success',
  props<{ user: UserModel }>()
);

export const superAdminClientCreated = createAction(
  '[User] Super admin client added',
  props<{ newClient: UserClient }>()
);
