import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { COUNTRY_LIST, CountryCodes, DEFAULT_COUNTRY, getMapBoundariesByCountry } from '@app-lib';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { GoogleMap, MapAdvancedMarker } from '@angular/google-maps';
import { MatInput } from '@angular/material/input';

export interface AutocompleteAddress {
  address: string;
  state: string;
  city: string;
  zipCode: string;
  lat: number | null;
  lng: number | null;
}

@Component({
  standalone: true,
  selector: 'app-google-place-autocomplete',
  templateUrl: './google-place-autocomplete.component.html',
  imports: [MatFormField, FormsModule, GoogleMap, MapAdvancedMarker, MatInput],
})
export class GooglePlaceAutocompleteComponent implements OnChanges, AfterViewInit {
  @Input() restrictionArea = [DEFAULT_COUNTRY.code];
  @Input() coordinates: { lng: number; lat: number } | null = null;
  @ViewChild('place') place!: ElementRef;
  @Output() addressChanged = new EventEmitter<AutocompleteAddress>();
  readonly mapsApiKey: string = '2a6238c26271516b';
  private autocomplete: google.maps.places.Autocomplete | undefined;
  public autocompleteInput = '';
  public defaultLocationZoomLevel = 18;
  public mapCenter: { lng: number; lat: number } | google.maps.LatLng | null = null;
  public selectedLocation: google.maps.places.PlaceResult | null = null;

  mapOptions: google.maps.MapOptions = {
    mapId: this.mapsApiKey,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    scrollwheel: false,
    zoomControl: false,
    disableDoubleClickZoom: true,
    draggable: false,
  };
  map: google.maps.Map | undefined = undefined;
  markerImg: Node;

  initAutocomplete() {
    if (this.place && this.map) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.place.nativeElement,
        this.placeOptions(this.restrictionArea[0])
      );
      if (this.autocomplete && this.autocomplete.addListener) {
        this.autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            if (this.autocomplete) {
              this.selectedLocation = this.autocomplete.getPlace();
              const location = this.selectedLocation?.geometry?.location;
              if (!location) return;
              this.initMarkerOnMap(location);
              this.normalizeAddress();
              this.cdr.detectChanges();
            }
          });
        });
      }
    }
  }

  mapInitialized(map: google.maps.Map) {
    this.map = map;

    this.initAutocomplete();

    if (this.coordinates) {
      this.initMarkerOnMap(this.coordinates);
    } else {
      this.initCountryOnMap();
    }
  }

  initMarkerOnMap(location: { lng: number; lat: number } | google.maps.LatLng) {
    if (location) {
      const markerCoordinates = location;
      this.map?.setCenter(markerCoordinates);
      this.mapCenter = markerCoordinates;
      this.map?.setZoom(this.defaultLocationZoomLevel);
    }
  }

  async initCountryOnMap() {
    const country = COUNTRY_LIST.find(country => country.code === this.restrictionArea[0]);
    if (!country) return;

    const geocodeResult = await getMapBoundariesByCountry(country.name);
    if (!geocodeResult.geometry.bounds) return;

    this.map?.fitBounds(geocodeResult.geometry.viewport);

    // need to make map zoom bigger after setting up country boundaries
    this.ngZone.run(() => {
      const zoom = this.map?.getZoom();
      if (!zoom) return;
      this.map?.setZoom(zoom + 1);
      this.cdr.detectChanges();
    });
  }

  // Set autocomplete options
  placeOptions(restrictionArea = 'us') {
    return {
      componentRestrictions: { country: restrictionArea },
      fields: ['address_components', 'geometry', 'formatted_address'],
      strictBounds: false,
    };
  }

  constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef) {
    const beachFlagImg = document.createElement('img');
    beachFlagImg.src = 'assets/icons/marker-default.svg';

    this.markerImg = beachFlagImg;
  }

  // Country changed logic
  ngOnChanges(changes: SimpleChanges): void {
    if (this.restrictionArea && changes['restrictionArea'] && this.autocomplete?.setComponentRestrictions) {
      this.autocomplete.setComponentRestrictions({ country: this.restrictionArea });

      if (!this.selectedLocation) {
        this.initCountryOnMap();
      }
    }
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  // Address data parsing
  normalizeAddress() {
    if (
      !this.selectedLocation ||
      !this.selectedLocation.address_components ||
      !this.selectedLocation.geometry?.location
    )
      return;
    const component = this.selectedLocation.address_components;
    const res: AutocompleteAddress = {
      address: '',
      state: '',
      city: '',
      zipCode: '',
      lat: this.selectedLocation.geometry.location.lat(),
      lng: this.selectedLocation.geometry.location.lng(),
    };

    for (const address of component) {
      const types = address.types[0];
      switch (types) {
        case 'street_number': {
          res.address = `${address.long_name} ${res.address}`;
          break;
        }

        case 'premise':
        case 'route': {
          res.address += address.short_name;
          break;
        }

        case 'postal_code': {
          res.zipCode = `${address.long_name}${res.zipCode}`;
          break;
        }

        case 'postal_code_suffix': {
          res.zipCode = `${res.zipCode}-${address.long_name}`;
          break;
        }

        case 'postal_town':
        case 'locality':
          res.city = address.long_name;
          break;

        case 'administrative_area_level_1': {
          res.state = address.long_name;
          break;
        }
      }
    }
    // Hong-kong data normalizing
    if (this.restrictionArea[0] === CountryCodes['Hong Kong']) {
      const hk = COUNTRY_LIST.find(item => item.code === CountryCodes['Hong Kong']);
      if (!res.zipCode) {
        if (hk?.mapSettings.defaultZipCode) {
          res.zipCode = hk?.mapSettings.defaultZipCode;
        }
      }
      if (!res.city) {
        res.city = <string>hk?.name;
      }
    }
    this.addressChanged.emit(res);
  }

  clearAutocomplete() {
    this.autocompleteInput = '';
    this.selectedLocation = null;
    this.addressChanged.emit({ address: '', city: '', lat: null, lng: null, state: '', zipCode: '' });
  }
}
