<h1 class="dialog-title">
  <span>Edit Data Point</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" (ngSubmit)="saveChanges()" class="change-password-popup">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="friendly-name" class="input-main-label">Friendly data point name:</label>
      <mat-form-field class="input-main">
        <input matInput id="friendly-name" type="text" formControlName="friendlyName" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="name" class="input-main-label">Data point name:</label>
      <mat-form-field class="input-main">
        <input id="name" matInput type="text" [disabled]="true" formControlName="name" />
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="attribute-type" class="input-main-label">Attribute type:</label>
      <mat-form-field class="input-main">
        <input matInput [disabled]="true" id="attribute-type" type="text" formControlName="attributeType" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
    <button type="submit" class="btn-green btn-medium font-bold" [disabled]="(isLoading$ | async) === true">
      @if ( (isLoading$ | async) === true){
      <mat-spinner diameter="20" class="mr-1"></mat-spinner>
      } SAVE
    </button>
  </mat-dialog-actions>
</form>
