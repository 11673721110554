import { InstanceTypes } from '@models';

export const environment = {
  production: false,
  api: 'https://be-dev.harmonyconnected.com/api/',
  instance: InstanceTypes.DEV,
  mobileAppUrl: 'https://ashy-rock-09653f00f.5.azurestaticapps.net/',
  useContextApiVersioning: true,

  featureToggles: {
    useDevicesTableV2: true,
  },
};
