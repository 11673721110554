import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { EntityStatus, FloorSpaceModel, IncidentStatus, LocationFullData, RoomSpaceModel, SpaceStats } from '@models';
import { MatSelectChange } from '@angular/material/select';
import { StatusTextColor } from '@app-lib';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';

@Component({
  selector: 'app-building-info',
  templateUrl: './building-info.component.html',
  styleUrls: ['./building-info.component.scss'],
})
export class BuildingInfoComponent implements OnChanges {
  protected readonly EntityStatus = EntityStatus;
  @ViewChild('floorSearchInput') floorSearchInputComponent!: SearchInputComponent;
  @ViewChild('roomSearchInput') roomSearchInputComponent!: SearchInputComponent;
  @Input() buildingStats!: SpaceStats;
  @Input() locationData: LocationFullData | undefined;
  @Input() currentFloorId: string | null = null;
  @Input() currentRoomId: string | null = null;
  @Input() floorName!: string;
  @Output() spaceChanged = new EventEmitter<{ spaceId: string; isFloorSwitch: boolean }>();
  floorList: FloorSpaceModel[] = [];
  roomList: RoomSpaceModel[] = [];
  currentFloor: FloorSpaceModel | undefined;
  currentRoom: RoomSpaceModel | undefined;
  statusColors = StatusTextColor;
  entityStatus = EntityStatus;
  incidentStatus = IncidentStatus;
  searchFloor = '';
  searchRoom = '';

  ngOnChanges() {
    this.initData();
  }

  initData() {
    if (this.locationData && this.currentFloorId) {
      this.floorList = this.locationData?.floors;
      this.currentFloor = this.floorList.find(floor => floor.id === this.currentFloorId);
      if (this.currentRoomId) {
        this.roomList = this.currentFloor?.rooms?.filter(({ status }) => status !== EntityStatus.Archived) || [];
        this.currentRoom = this.currentFloor?.rooms?.find(room => room.id === this.currentRoomId);
      }
    }
  }

  switchSpace(event: MatSelectChange, isFloorSwitch = false) {
    this.floorSearchInputComponent?.clear();
    this.roomSearchInputComponent?.clear();
    this.spaceChanged.emit({ spaceId: event.value, isFloorSwitch });
  }

  get fullAddress() {
    return this.locationData
      ? `${this.locationData.address?.streetAddress}, ${this.locationData.address?.city},
    ${this.locationData.address?.stateProvince}, ${this.locationData.address?.postalCode},
    ${this.locationData.address?.country}`
      : '';
  }
}
