import { Component, DestroyRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceData, EntityStatus, IncidentModel, IncidentStatus } from '@models';
import { NotificationsService } from '@services';
import { AppState, isDeviceUpdating, rebootDevice } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-device-info-block',
  templateUrl: './device-info-block.component.html',
  styleUrls: ['./device-info-block.component.scss'],
})
export class DeviceInfoBlockComponent implements OnChanges {
  protected readonly EntityStatus = EntityStatus;
  @Input() device!: DeviceData;
  @Input() incidents: IncidentModel[] = [];
  @Input() clientId!: string;
  destroyRef = inject(DestroyRef);
  deviceIsUpdating$ = this.store.select(isDeviceUpdating);
  incidentStatus = IncidentStatus;
  tempSensor: string | null | undefined = undefined;

  constructor(
    private notificationMessage: NotificationsService,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) {}

  copiedToClipboard() {
    this.notificationMessage.showSuccessMessage('Copied to clipboard');
  }

  getDeviceUrl(device: DeviceData): string {
    return device.tags?.['ipAddress'] + '/' + (device.tags?.['hyperlink'] || '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.initDeviceAttributes();
    }
  }

  initDeviceAttributes() {
    if (this.device.additionalAttributes) {
      this.tempSensor = this.device.additionalAttributes?.find(attribute => attribute.name === 'tempSensor')?.value;
    }
  }

  rebootDevice() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Reboot Device',
      description: 'Are you sure you want to reboot the device? ',
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(rebootDevice({ deviceId: this.device.id, locationId: this.device.location.id }));
        }
      });
  }
}
