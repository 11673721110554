import * as AuthActions from './user/auth.actions';
import * as LocationActions from './locations/locations.actions';
import * as AppSettingsActions from './app-settings/app-settings.actions';
import * as ClientUsersActions from './client-users';
import * as ClientManagementActions from './clients-managements';
import * as DevicesActions from './devices/devices.actions';
import * as IncidentsActions from './incidents/incidents.actions';
import { createAction, props } from '@ngrx/store';

export const actionError = createAction('[Service request error] service error', props<{ error: Error }>());
export {
  AuthActions,
  LocationActions,
  AppSettingsActions,
  ClientUsersActions,
  ClientManagementActions,
  DevicesActions,
  IncidentsActions,
};
