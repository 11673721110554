<div [formGroup]="form" [class.mt-3]="config().reportMode">
  @if (!config().reportMode) {
  <div class="test-button-group border-b-ui-gray-light border-b flex justify-between items-center mb-3">
    <mat-button-toggle-group class="desktop-toggle-group" formControlName="status" aria-label="Device Status">
      <mat-button-toggle class="test-device-status-current" [value]="eEntityStatus.Active">
        <span class="item-status-title">Current</span>
      </mat-button-toggle>
      <mat-button-toggle
        class="test-device-status-archived"
        [value]="eEntityStatus.Archived">
        <span class="item-status-title">Archived</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    @if (queryFilterEnabled()) {
    <div class="test-search-panel flex justify-center">
      <app-table-search-input
        placeholder="Device Name, type & other"
        [tableId]="tableId()"
        [inputValue]="form.value.query!"
        (searchDataEvent)="form.patchValue({ query: $event })" />
    </div>
    }
  </div>
  }

  <div
    class="test-filters-panel flex"
    [ngClass]="filtersEnabled() && !config().hide ? 'justify-between' : 'justify-end'">
    @if (filtersEnabled() && !config().hide) {
    <div class="flex items-start flex-wrap">
      @if (config().deviceTypeFilter) {
      <app-multiple-select
        class="test-device-type-select mr-2"
        panelClass="device-type-overlay"
        selectClass="stroked-select-multiple mb-3"
        label="Device Type"
        [options]="options()['deviceTypes'] || []"
        [value]="form.value.deviceType!"
        (selectDataEvent)="form.patchValue({ deviceType: $event })" />
      } @if (config().manufacturerFilter) {
      <app-multiple-select
        class="test-manufacturer-select mr-2"
        panelClass="assigned-user-overlay"
        selectClass="stroked-select-multiple mb-3"
        label="Manufacturer"
        [options]="options()['deviceMakers'] || []"
        [value]="form.value.deviceMaker!"
        (selectDataEvent)="form.patchValue({ deviceMaker: $event })" />
      } @if (config().locationFilter) {
      <mat-form-field class="test-building-select stroked-select-single mb-3 mr-2">
        <mat-label>Building</mat-label>
        <mat-select
          formControlName="building"
          panelClass="single-select-overlay"
          (valueChange)="form.patchValue({ floor: undefined, room: undefined })"
        >
          <mat-option [value]="null">
            All
          </mat-option>
          @for (building of options()['locations'] || [] | sort : 'asc' : 'title' : 'all' ; track building){
          <mat-option [value]="building.value">
            {{ building.title }}
          </mat-option>
          }
        </mat-select>
        <mat-icon matSuffix class="arrow-down"> keyboard_arrow_down </mat-icon>
        <mat-icon matSuffix class="arrow-up"> keyboard_arrow_up </mat-icon>
      </mat-form-field>
      } @if (form.value.building) {
      <mat-form-field class="test-floor-select stroked-select-single mb-3 mr-2">
        <mat-label>Floor</mat-label>
        <mat-select
          formControlName="floor"
          panelClass="single-select-overlay"
          (valueChange)="form.patchValue({ room: undefined })">
          <mat-option *ngFor="let floor of floorOptions() | sort : 'asc' : 'title' : 'all'" [value]="floor.value">
            {{ floor.title }}
          </mat-option>
        </mat-select>
        <mat-icon class="arrow-down" matSuffix> keyboard_arrow_down </mat-icon>
        <mat-icon class="arrow-up" matSuffix> keyboard_arrow_up </mat-icon>
      </mat-form-field>
      } @if (form.value.floor) {
      <mat-form-field class="test-room-select stroked-select-single mb-3 mr-2">
        <mat-label>Room</mat-label>
        <mat-select formControlName="room" panelClass="single-select-overlay device-room-filter-overlay">
          <mat-option *ngFor="let room of roomOptions() | sort : 'asc' : 'title' : 'all'" [value]="room.value">
            {{ room.title }}
          </mat-option>
        </mat-select>
        <mat-icon class="arrow-down" matSuffix> keyboard_arrow_down </mat-icon>
        <mat-icon class="arrow-up" matSuffix> keyboard_arrow_up </mat-icon>
      </mat-form-field>
      } @if (hasFiltersSelected()) {
      <button
        class="test-clear-all-filters font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 mb-3"
        (click)="clearAllFilters()">
        Clear All
      </button>
      }
    </div>
    } @if (config().columnSelectOptions && queryFilterEnabled()) {
    <mat-form-field class="column-select mb-3 justify-self-end">
      <mat-label matTooltip="Column Select">
        <i class="icon-ic_column text-3xl"></i>
      </mat-label>
      <mat-select formControlName="columns" panelClass="multi-select-overlay column-select-overlay" multiple>
        <div class="options-action-wrapper">
          <button class="options-action-button" (click)="selectAllColumns()">Select All</button>
        </div>
        <mat-option *ngFor="let option of config().columnSelectOptions" [value]="option.value">
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    }
  </div>
</div>
