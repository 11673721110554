@let myClient = myClient$ | async;

<div class="flex justify-end mb-4 -mt-14">
  <button class="btn-green btn-medium" (click)="openAddDeviceModelDialog()">
    <mat-icon fontIcon="add" />
    Add New Model
  </button>
</div>
<mat-card class="card shadow-card overflow-hidden h-full">
  <div class="h-full overflow-hidden flex flex-col">
    <div>
      <div class="flex justify-between items-center mb-4">
        <mat-card-title class="card-title-small">Model list</mat-card-title>
        <app-search-input
          placeholder="Model name"
          [inputValue]="modelSearch"
          (searchDataEvent)="modelSearchChange($event)" />
      </div>

      <div>
        <app-multiple-select
          [value]="deviceTypeValue"
          [options]="(deviceHelperService.deviceTypeOptions$ | async) || []"
          label="Device Type"
          selectClass="stroked-select-multiple"
          panelClass="device-type-overlay"
          class="test-device-type-select mr-2"
          (selectDataEvent)="setDeviceType($event)" />

        <app-multiple-select
          [value]="manufacturerValue"
          [options]="manufacturerOptions"
          label="Manufacturer"
          selectClass="stroked-select-multiple"
          panelClass="assigned-user-overlay"
          class="test-manufacturer-select mr-2"
          (selectDataEvent)="setManufacturer($event)" />
      </div>
    </div>
    <div class="overflow-y-auto">
      <div class="border border-ui-gray-light sticky top-0">
        <div class="flex items-center bg-ui-gray-50 font-nunito text-sm text-ui-gray-100 font-semibold h-8">
          <div class="flex px-3 items-center w-[13%]">
            <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">№</span>Img
          </div>
          <div class="flex px-3 items-center w-[26%]">Model name</div>
          <div class="flex px-3 items-center w-[26%]">Device type</div>
          <div class="flex px-3 items-center w-[35%]">Manufacturer</div>
        </div>
      </div>
      <ul class="border border-ui-gray-light border-t-0 border-b-0">
        @for (model of filteredDeviceModels; track model.id) {
        <li [ngClass]="{ 'h-0 overflow-hidden': !model.show }">
          <div class="border-b border-b-ui-gray-light py-2 min-h-11 flex items-center font-nunito text-sm text-ui-dark">
            <div class="flex px-3 w-[13%] items-center">
              <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">{{ model.index }}</span>
              <div class="w-[60px]">
                @if (myClient && model.documentReference) {
                <app-img-loader
                  loader="skeleton"
                  altText="Device Model"
                  errorSrc="assets/icons/image-error.svg"
                  [loaderHeight]="40"
                  [imgSrc]="
                    'clientId/' +
                    myClient.id +
                    '/library/standardMake/' +
                    model.make.id +
                    '/standardModel/' +
                    model.id +
                    '/document/' +
                    model.documentReference
                  " />
                } @else {
                <img alt="Device Image" src="assets/icons/image-placeholder.svg" />
                }
              </div>
            </div>
            <div class="flex px-3 w-[26%] items-center">
              {{ model.name }}
            </div>
            <div class="flex px-3 w-[26%] items-center">
              {{ model.deviceType }}
            </div>
            <div class="flex px-3 w-[35%] items-center">
              {{ model.make.name }}
            </div>
          </div>
        </li>
        }
      </ul>
    </div>
  </div>
</mat-card>
