import { createReducer, on } from '@ngrx/store';
import { menuModeSwitched } from './layout.actions';

export interface LayoutState {
  menuShrunk: boolean;
}
export const InitialLayoutState: LayoutState = {
  menuShrunk: JSON.parse(localStorage.getItem('menuShrunk') || 'false'),
};

export const layoutReducer = createReducer(
  InitialLayoutState,
  on(menuModeSwitched, (state, action) => {
    return { ...state, menuShrunk: action.menuShrunk };
  })
);
