@let device = device$ | async; @let myClient = myClient$ | async;
<div class="test-device-details" *ngIf="device; else loading">
  <div class="test-header flex flex-row items-center pb-3">
    <div class="flex-grow">
      <a
        [routerLink]="backButtonUrl"
        class="cursor-pointer text-sm inline-flex text-ui-gray-dark font-semibold items-center hover:underline">
        <mat-icon>chevron_left</mat-icon>
        Back to {{ backButtonType }}
      </a>
      <app-page-header>{{ device.friendlyName }}</app-page-header>
      <!--      <app-breadcrumbs [routeNames]="[device.friendlyName]"/>-->
    </div>
    <div class="flex">
      <button
        appRestrictedEl
        [showForClientAdmin]="true"
        *ngIf="device.status === EntityStatus.Paused"
        (click)="resumeDevice(device)"
        class="test-resume-btn btn-green btn-medium mr-3">
        <i class="icon-play-16 text-3xl mr-2"></i>
        Resume
      </button>
      <button
        appRestrictedEl
        [showForClientAdmin]="true"
        *ngIf="device.status === EntityStatus.Active"
        (click)="pauseDevice(device)"
        class="test-pause-btn btn-gray btn-medium mr-3">
        <i class="icon-pause-16 text-3xl mr-2"></i>
        Pause
      </button>
      <button
        *ngIf="device.status !== EntityStatus.Archived"
        appRestrictedEl
        (click)="archiveDevice(device)"
        class="test-archive-btn btn-black btn-medium mr-3">
        <i class="icon-add-to-archive text-3xl mr-2"></i>
        Archive
      </button>
      <button
        *ngIf="device.status === EntityStatus.Archived"
        appRestrictedEl
        (click)="restoreDevice(device)"
        class="test-restore-btn btn-green btn-medium mr-3">
        <i class="icon-restore text-3xl mr-2"></i>
        Restore
      </button>
      <button
        *ngIf="device.status !== EntityStatus.Archived"
        appRestrictedEl
        class="test-edit-btn btn-blue btn-medium"
        [routerLink]="['/devices', 'edit', device.location.id || device.locationId, device.id]">
        <i class="icon-edit text-3xl"></i>
        Edit
      </button>
    </div>
  </div>

  <section class="flex flex-row align-top max-h-[100%]">
    @if (myClient){
    <app-device-info-block [device]="device" [incidents]="(incidents$ | async) || []" [clientId]="myClient.id" />
    }

    <div class="ml-4 w-[calc(100%-320px)]">
      <app-device-location-widget [device]="device" />
      <app-device-stats-chart [device]="device" [isMobile]="false" />

      <mat-card class="card pb-2">
        <mat-card-title class="card-title">Incidents</mat-card-title>
        <mat-card-content class="p-0">
          <app-incidents-table-desktop-filters
            tableId="device-details-incidents-table"
            [filtersEnabled]="true"
            [resolvedIncidentsLoading]="resolvedIncidentsLoading"
            [dropdownFilters]="false" />
          <app-incidents-table
            tableId="device-details-incidents-table"
            [isInjected]="true"
            [dataIsLoading]="resolvedIncidentsLoading"
            noDataText="This device is working properly" />
        </mat-card-content>
      </mat-card>
      <div class="card-separator"></div>
    </div>
  </section>
</div>

<ng-template #loading>
  <app-device-details-skeleton-animation />
</ng-template>
