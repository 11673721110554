import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute } from '@angular/router';
import { defaultDocumentTab } from '@app-lib';
import { DeepPartial, DeviceData, FloorSpaceModel, RoomSpaceModel, UserRolesIds } from '@models';
import { AppState, locationState, updateRoom, userRole } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { AttachmentDevicePopoverComponent } from '@standalone/attachment-popovers/attachment-device-popover/attachment-device-popover.component';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
})
export class EditRoomComponent {
  locationId: string | null;
  floor: FloorSpaceModel | undefined;
  room: RoomSpaceModel | undefined;
  userRoleIds = UserRolesIds;
  currentUserRoleId = '';
  deviceTab: 'list' | 'add' = 'list';
  deviceToEdit: DeviceData | null = null;
  selectedDocumentTab = defaultDocumentTab;
  destroyRef = inject(DestroyRef);
  editDevicePopover = AttachmentDevicePopoverComponent;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private appService: AppService) {
    this.locationId = route.snapshot.paramMap.get('locationId');
    const floorId = route.snapshot.paramMap.get('floorId');
    const roomId = route.snapshot.paramMap.get('roomId');

    this.store
      .select(locationState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(building => {
        if (building) {
          this.floor = building.floors.find(({ id }) => id === floorId);
          if (!this.floor) return;
          this.room = this.floor.rooms?.find(room => room.id === roomId);

          if (!this.room) return;
        }
      });

    this.store
      .select(userRole)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(roleId => (this.currentUserRoleId = roleId));
  }

  onRoomChange(fieldData: Record<string, string>) {
    this.updateRoom(fieldData);
  }

  updateRoom(roomData: DeepPartial<RoomSpaceModel>) {
    if (this.locationId && this.room?.id) {
      this.store.dispatch(
        updateRoom({
          spaceId: this.room.id,
          locationId: this.locationId,
          data: roomData,
        })
      );
    }
  }

  tabChange(change: MatButtonToggleChange) {
    this.deviceTab = change.value;
    if (this.deviceTab === 'list') {
      this.deviceToEdit = null;
    }
  }

  editDevice(device: DeviceData) {
    this.deviceToEdit = device;
    this.deviceTab = 'add';
  }
}
