import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { LocationsState, locationsReducer } from './locations';
import { authReducer, UserState } from './user';
import { clientUsersReducer, ClientUserState } from './client-users';
import { DevicesState, devicesReducer } from './devices';
import { IncidentsState, incidentsReducer } from './incidents';
import { ChildClientState, clientsManagementsReducer } from './clients-managements';
import { layoutReducer, LayoutState } from '@layout';

export interface AppState {
  auth: UserState;
  clientSettings?: {
    selectedClient: string;
  };
  locations: LocationsState;
  clientUsers: ClientUserState & { allUsersLoaded: boolean; isLoading: boolean };
  clients: ChildClientState & { isLoading: boolean };
  devices: DevicesState;
  incidents: IncidentsState;
  layout: LayoutState;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  locations: locationsReducer,
  router: routerReducer,
  auth: authReducer,
  clientUsers: clientUsersReducer,
  devices: devicesReducer,
  layout: layoutReducer,
  incidents: incidentsReducer,
  clients: clientsManagementsReducer,
};
