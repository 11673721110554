import { BehaviorSubject, combineLatest, map, Observable, startWith, Subject, tap, throttleTime } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatTableDataSource } from '@angular/material/table';

const PAGE_SIZE = 50;
const LOAD_MORE_THRESHOLD = 0.8;

export class FixedSizeItemVirtualViewportTableDataSource<T> extends MatTableDataSource<T> {
  itemSize?: number;
  offset$ = new BehaviorSubject<number>(0);
  thresholdReached$: Observable<void>;
  viewport?: CdkVirtualScrollViewport;

  protected threshold$ = new Subject<void>();

  constructor(initialData?: T[]) {
    super(initialData);

    this.thresholdReached$ = this.threshold$.pipe(throttleTime(1000));
  }

  override connect() {
    if (!this.itemSize || !this.viewport) {
      throw new Error('ItemSize and Viewport must be provided');
    }

    const index$ = this.viewport.scrolledIndexChange.pipe(
      startWith(0),
      tap(index => {
        this.offset$.next(index * this.itemSize!);

        if (index >= this.filteredData.length * LOAD_MORE_THRESHOLD - 10) {
          this.threshold$.next(void 0);
        }
      })
    );

    return combineLatest([super.connect(), index$]).pipe(
      map(([data, index]) => data.slice(index, index + PAGE_SIZE))
    ) as BehaviorSubject<T[]>;
  }
}
