import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { attributeNameValidationPattern, removeSpacesValidator } from '@app-lib';
import { DeviceAttribute, DeviceAttributeType, SelectOption, UserClient, DeviceModel } from '@models';
import { DeviceHelperService } from '@services';
import { openAddManufacturerDialog } from '../add-manufacturer-dialog/add-manufacturer-dialog.component';
import { openAddDeviceTypeDialog } from '../add-device-type-dialog/add-device-type-dialog.component';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@pipes';
import { MatInput } from '@angular/material/input';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import {
  addNewAttribute,
  addNewDeviceModel,
  AppState,
  DevicesActions,
  getAttributes,
  getDeviceManufacturers,
  getDeviceModels,
  getMyClient,
  isDeviceModelCreating,
  isDeviceModelImageUploading,
  isNewAttributeCreating,
  uploadDeviceModelImage,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { Actions, ofType } from '@ngrx/effects';
import { MatCardTitle } from '@angular/material/card';
import { NewAttributeFormComponent } from '@standalone/new-attribute-form/new-attribute-form.component';

type DeviceModelDialogProps = {
  selectedDeviceType: string;
  selectedManufacturer: string;
};

@Component({
  selector: 'app-add-device-model-dialog',
  templateUrl: './add-device-model-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatSelect,
    MatIcon,
    MatError,
    PipesModule,
    ReactiveFormsModule,
    MatDialogClose,
    MatInput,
    MatDialogActions,
    MatOption,
    NgForOf,
    SearchInputComponent,
    MatSuffix,
    MatProgressSpinner,
    AsyncPipe,
    NgIf,
    MatCheckbox,
    MatCardTitle,
    NewAttributeFormComponent,
  ],
})
export class AddDeviceModelDialogComponent {
  @ViewChild(NewAttributeFormComponent) newAttributeFormComponent: NewAttributeFormComponent | undefined;
  form: FormGroup;
  newAttributeForm: FormGroup;
  deviceTypeOptions: SelectOption[] = [];
  manufacturerOptions: SelectOption[] = [];
  manufacturerFilterValue = '';
  deviceTypeFilterValue = '';
  isDeviceModelCreating$: Observable<boolean | undefined>;
  isDeviceModelImageUploading$: Observable<boolean | undefined>;
  client: UserClient | undefined;
  attributes: DeviceAttribute[] = [];
  file: File | undefined;
  imagePreviewSrc = '';
  isNewAttributeCreating$: Observable<boolean | undefined>;
  attributeTypeOptions: SelectOption[] = [
    {
      title: 'Property',
      value: DeviceAttributeType.PROPERTY,
    },
    {
      title: 'Realtime',
      value: DeviceAttributeType.REALTIME,
    },
  ];
  deviceModels: DeviceModel[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private deviceHelperService: DeviceHelperService,
    public dialogRef: MatDialogRef<AddDeviceModelDialogComponent>,
    private dialog: MatDialog,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: DeviceModelDialogProps
  ) {
    this.isDeviceModelCreating$ = this.store.select(isDeviceModelCreating);
    this.isDeviceModelImageUploading$ = this.store.select(isDeviceModelImageUploading);
    this.isNewAttributeCreating$ = this.store.select(isNewAttributeCreating);

    this.form = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator]],
      deviceType: [data.selectedDeviceType, [Validators.required]],
      manufacturer: [data.selectedManufacturer, [Validators.required]],
      attributes: new FormArray([]),
    });

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
        }
      });
    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    this.deviceHelperService.deviceTypeOptions$
      .pipe(takeUntilDestroyed())
      .subscribe(deviceTypeOptions => (this.deviceTypeOptions = deviceTypeOptions));

    this.store
      .select(getAttributes)
      .pipe(takeUntilDestroyed())
      .subscribe(attributes => {
        if (attributes.length) {
          this.attributes = attributes.filter(attribute => attribute.attributeType === DeviceAttributeType.REALTIME);
        }
      });

    this.store
      .select(getDeviceModels)
      .pipe(takeUntilDestroyed())
      .subscribe(models => {
        this.deviceModels = models;
      });

    actions$.pipe(ofType(DevicesActions.addNewDeviceModelSuccess), takeUntilDestroyed()).subscribe(action => {
      if (this.file) {
        this.store.dispatch(
          uploadDeviceModelImage({
            file: this.file,
            deviceMakeId: action.deviceModel.make.id,
            deviceModelId: action.deviceModel.id,
            clientId: action.deviceModel.ownerClientId,
          })
        );
      }
    });

    actions$.pipe(ofType(DevicesActions.uploadDeviceModelImageSuccess), takeUntilDestroyed()).subscribe(() => {
      this.file = undefined;
      this.imagePreviewSrc = '';
    });

    actions$.pipe(ofType(DevicesActions.addNewAttributeSuccess), takeUntilDestroyed()).subscribe(() => {
      this.newAttributeFormComponent?.resetForm();
    });

    this.newAttributeForm = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator, Validators.pattern(attributeNameValidationPattern)]],
      type: [{ value: DeviceAttributeType.REALTIME, disabled: true }],
    });
  }

  realtimeAttributeChange(event: MatCheckboxChange, i: number) {
    const formArray: FormArray = this.form.get('attributes') as FormArray;

    if (event.checked) {
      formArray.push(new FormControl(event.source.value));
    } else {
      formArray.controls.forEach((ctrl, i) => {
        if (ctrl.value == event.source.value) {
          formArray.removeAt(i);
          return;
        }
      });
    }
  }

  onFileChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.file = file;
      this.imagePreviewSrc = URL.createObjectURL(file);
    }
  }

  deleteFile() {
    this.file = undefined;
    this.imagePreviewSrc = '';
  }

  createModel() {
    if (this.form.valid && this.client) {
      if (this.checkModelNameAlreadyExist()) {
        this.form.controls['name'].setErrors({ alreadyExist: true });
        return;
      }

      this.store.dispatch(
        addNewDeviceModel({
          clientId: this.client.id,
          makeId: this.form.value.manufacturer,
          newDevicdeModelData: {
            name: this.form.value.name,
            deviceType: this.form.value.deviceType,
            standardAttributeIds: this.form.value.attributes,
          },
        })
      );
      // this.notificationService.showSuccessMessage(`A new model [${this.form.value.name}] was successfully created`);
      // this.dialogRef.close();
    }
  }

  editDeviceType(event: Event) {
    // prevents option selection
    event.stopPropagation();
  }

  editManufacturer(event: Event) {
    // prevents option selection
    event.stopPropagation();
  }

  openAddManufacturerDialog() {
    openAddManufacturerDialog(this.dialog);
  }

  manufacturerSearch(value: string) {
    this.manufacturerFilterValue = value;
  }

  openAddDeviceTypeDialog() {
    openAddDeviceTypeDialog(this.dialog);
  }

  deviceTypeSearch(value: string) {
    this.deviceTypeFilterValue = value;
  }

  createAttribute(data: Pick<DeviceAttribute, 'name' | 'attributeType'>) {
    if (this.client) {
      this.store.dispatch(addNewAttribute({ clientId: this.client.id, data }));
    }
  }

  checkAttributeAlreadyExist() {
    return this.attributes.some(({ name }) => name.toLowerCase() === this.newAttributeForm.value.name.toLowerCase());
  }

  checkModelNameAlreadyExist() {
    return this.deviceModels.some(({ name }) => name.toLowerCase() === this.form.value.name.toLowerCase());
  }
}

export function openAddDeviceModelDialog(dialog: MatDialog, props: DeviceModelDialogProps) {
  const config = new MatDialogConfig();
  config.data = props;
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddDeviceModelDialogComponent, config);
  return dialogRef.afterClosed();
}
