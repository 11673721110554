import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_LIST, DEFAULT_COUNTRY, removeSpacesValidator } from '@app-lib';
import { Store } from '@ngrx/store';
import { addChildClient, AppState, childClientIsLoading } from '@ngrx-store';
import { Observable } from 'rxjs';
import { PhoneNumberClass } from '@abstraction';

@Component({
  selector: 'app-add-client-form',
  templateUrl: './add-client-form.component.html',
  styleUrl: './add-client-form.component.scss',
})
export class AddClientFormComponent extends PhoneNumberClass {
  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  form: FormGroup;
  isLoading$!: Observable<boolean>;
  sliderMinValue = 5;
  sliderMaxValue = 30;
  defaultSliderValue = 10;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    super();
    this.isLoading$ = this.store.select(childClientIsLoading);
    this.form = this.fb.group({
      friendlyName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      inheritParentLibrary: [true],
      tags: fb.group({
        website: [''],
        phoneNumber: [''],
        ticketingClientId: [''],
        countryCode: [DEFAULT_COUNTRY.code],
        countryNumberCode: [DEFAULT_COUNTRY.phoneCode],
      }),
      description: [''],
      clientUserSettings: fb.group({
        userSessionTimeoutMinutes: [this.defaultSliderValue, [Validators.required]],
      }),
    });
  }

  submitForm() {
    if (!this.form.valid) return;
    const countryCode = this.form.get('tags.countryCode')?.value;
    this.form
      .get('tags.countryNumberCode')
      ?.setValue(COUNTRY_LIST.find(country => country.code === countryCode)?.phoneCode || DEFAULT_COUNTRY.code);
    this.store.dispatch(addChildClient({ newUserData: this.form.value }));
  }

  updateSliderValue(sliderValue: number) {
    this.form.get('clientUserSettings.userSessionTimeoutMinutes')?.setValue(sliderValue);
  }
}
