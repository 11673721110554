import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addLocationSuccess, getLocationStateSuccess, LocationActions, locationError } from '@ngrx-store';
import { AppService, LocationService, NotificationsService } from '@services';
import { forkJoin, map, of, switchMap, take } from 'rxjs';
import {
  addFloorError,
  addFloorSuccess,
  addRoomError,
  addRoomSuccess,
  deleteFloorplanError,
  deleteFloorplanSuccess,
  deleteRoomplanError,
  deleteRoomplanSuccess,
  getRoomDocumentsSuccess,
  loadLocationsSummariesError,
  loadLocationsSummariesSuccess,
  locationsLoaded,
  updateFloorError,
  updateFloorSuccess,
  updateLocationSuccess,
  updateRoomError,
  updateRoomSuccess,
  uploadFloorplanError,
  uploadFloorplanSuccess,
  uploadRoomplanError,
  uploadRoomplanSuccess,
} from './locations.actions';
import { catchError, tap } from 'rxjs/operators';
import { EntityStatus, FloorSpaceModel, LocationData, LocationFullData, RoomSpaceModel, SpaceType } from '@models';
import { Router } from '@angular/router';

@Injectable()
export class LocationsEffects {
  loadLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadLocations),
      switchMap(action =>
        this.locationService.getLocationsList(action.clientId).pipe(
          map(locations => locationsLoaded({ location: locations })),
          catchError(async error => locationError({ locationError: error }))
        )
      )
    )
  );

  loadLocationsSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.locationsLoaded),
      switchMap(() =>
        this.locationService
          .getLocationsSummaries(this.appService.currentClient, { deviceStatus: ['active', 'paused'] })
          .pipe(
            map(locationsSummaries => {
              const locationsUpdates = locationsSummaries.map(({ id, summary }) => ({ id, changes: summary }));
              return loadLocationsSummariesSuccess({ data: locationsUpdates });
            }),
            catchError(async () => loadLocationsSummariesError())
          )
      )
    )
  );

  createLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.addLocation),
      switchMap(action =>
        this.locationService.addLocation(action.clientId, action.newLocation).pipe(
          map(location => addLocationSuccess({ newLocation: location })),
          catchError(error => of(locationError({ locationError: error })))
        )
      )
    );
  });

  locationCreated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.addLocationSuccess),
      tap(location => {
        this.notificationService.showSuccessMessage(
          'The new location - ' + location.newLocation.friendlyName + ' was created'
        );
      }),
      take(1)
    );
  });

  updateLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.updateLocation),
      switchMap(action => {
        return this.locationService.updateLocation(action.clientId, action.locationId, action.data).pipe(
          switchMap(updatedLocation =>
            this.locationService
              .getLocationsSummaries(action.clientId, {
                deviceStatus: ['active', 'paused'],
                locationId: action.locationId,
              })
              .pipe(
                map(locationsSummary => {
                  const summary = locationsSummary.find(summary => summary.id === updatedLocation.id)?.summary || {};
                  return { ...updatedLocation, ...summary };
                }),
                map(locationFullData => {
                  this.notificationService.showSuccessMessage(
                    'The location - ' + locationFullData.friendlyName + ' was updated'
                  );
                  return updateLocationSuccess({ location: locationFullData });
                }),
                catchError(error => of(locationError({ locationError: error })))
              )
          ),
          catchError(error => of(locationError({ locationError: error })))
        );
      })
    );
  });

  private buildLocationData(
    location: LocationData,
    floors: FloorSpaceModel[],
    excludeArchived: boolean
  ): LocationFullData {
    const locationData: LocationFullData = { ...location, floors: [] };
    locationData.floors = floors;
    if (excludeArchived) {
      locationData.floors = locationData.floors.filter(item => item.status !== EntityStatus.Archived);
    }
    return locationData;
  }

  private assignRoomsToFloors(
    locationData: LocationFullData,
    sortedRooms: {
      [key: string]: RoomSpaceModel[];
    }
  ): LocationFullData {
    if (locationData.floors.length) {
      locationData.floors.forEach((floor: FloorSpaceModel) => {
        floor.rooms = floor.id ? sortedRooms[floor.id] : [];
      });
    }
    return locationData;
  }

  getLocationState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getLocationState),
      switchMap(action => {
        const floorOptions: Record<string, string> = {
          type: SpaceType.floor,
          includeNestedDevices: 'true',
          includeArchived: 'true',
        };
        const roomOptions: Record<string, string> = { type: SpaceType.room, includeArchived: 'true' };

        return forkJoin([
          this.locationService.getLocationInfoById(action.clientId, action.locationId),
          this.locationService.getSpacesList(action.clientId, action.locationId, floorOptions),
          this.locationService.getSpacesList(action.clientId, action.locationId, roomOptions),
          this.locationService.getSpacesSummaries(action.clientId, action.locationId, {
            includeChildSpaceDevices: true,
            deviceStatus: ['active', 'paused'],
            type: 'Floor',
          }),
          this.locationService.getSpacesSummaries(action.clientId, action.locationId, {
            deviceStatus: ['active', 'paused'],
            type: 'Room',
          }),
        ]).pipe(
          map(([location, floors, rooms, floorSummaries, roomSummaries]) => {
            const fullDataFloors = floors.map(floor => {
              const floorSummary = floorSummaries.find(summary => floor.id === summary.id);
              return {
                ...floor,
                ...(floorSummary ? floorSummary.summary : {}),
              };
            });

            const fullDataRooms = rooms.map(room => {
              const roomSummary = roomSummaries.find(summary => room.id === summary.id);
              return {
                ...room,
                ...(roomSummary ? roomSummary.summary : {}),
              };
            });

            const sortedRooms = AppService.SortRoomsSpacesByParentId(fullDataRooms, action.excludeArchived);
            let locationData = this.buildLocationData(location, fullDataFloors, action.excludeArchived);
            locationData = this.assignRoomsToFloors(locationData, sortedRooms);

            return getLocationStateSuccess({ locationState: locationData });
          }),
          catchError(async error => locationError({ locationError: error }))
        );
      })
    )
  );

  locationStateLoaded$ = createEffect(() => {
    return this.actions$.pipe(ofType(LocationActions.getLocationStateSuccess), take(1));
  });

  locationErrorHandler = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.locationError),
        tap(error => {
          this.notificationService.showErrorMessage(error.locationError.message);
        })
      ),
    { dispatch: false }
  );

  // Floor effects
  createFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.addFloor),
      switchMap(action => {
        return this.locationService.addNewSpace(this.appService.currentClient, action.locationId, action.data).pipe(
          map(floor => {
            this.notificationService.showSuccessMessage(`Floor [${floor.friendlyName}] was successfully created`);
            return addFloorSuccess({ floor });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return addFloorError({ error });
          })
        );
      })
    );
  });

  updateFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.updateFloor),
      switchMap(action => {
        return this.locationService
          .updateSpace(this.appService.currentClient, action.locationId, action.spaceId, action.data)
          .pipe(
            switchMap(updatedFloor =>
              this.locationService
                .getSpacesSummaries(this.appService.currentClient, action.locationId, {
                  spaceId: action.spaceId,
                  includeChildSpaceDevices: true,
                  deviceStatus: ['active', 'paused'],
                })
                .pipe(
                  map(floorsSummary => ({ ...updatedFloor, ...floorsSummary[0].summary })),
                  map(floorFullData => {
                    this.notificationService.showSuccessMessage(
                      `Floor [${floorFullData.friendlyName}] was successfully updated`
                    );
                    return updateFloorSuccess({ floor: floorFullData, locationId: action.locationId });
                  }),
                  catchError(async error => {
                    this.notificationService.showErrorMessage(error.message);
                    return updateFloorError({ error });
                  })
                )
            ),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);
              return updateFloorError({ error });
            })
          );
      })
    );
  });

  // Room effects
  createRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.addRoom),
      switchMap(action => {
        return this.locationService.addNewSpace(this.appService.currentClient, action.locationId, action.data).pipe(
          map(room => {
            this.notificationService.showSuccessMessage(`Room [${room.friendlyName}] was successfully created`);
            return addRoomSuccess({ room });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return addRoomError({ error });
          })
        );
      })
    );
  });

  updateRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.updateRoom),
      switchMap(action => {
        return this.locationService
          .updateSpace(this.appService.currentClient, action.locationId, action.spaceId, action.data)
          .pipe(
            switchMap(updatedRoom =>
              this.locationService
                .getSpacesSummaries(this.appService.currentClient, action.locationId, {
                  spaceId: action.spaceId,
                  deviceStatus: ['active', 'paused'],
                })
                .pipe(
                  map(roomsSummary => ({ ...updatedRoom, ...roomsSummary[0].summary })),
                  map(roomFullData => {
                    this.notificationService.showSuccessMessage(
                      `Room [${roomFullData.friendlyName}] was successfully updated`
                    );
                    return updateRoomSuccess({ room: roomFullData });
                  }),
                  catchError(async error => {
                    this.notificationService.showErrorMessage(error.message);
                    return updateRoomError({ error });
                  })
                )
            ),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);
              return updateRoomError({ error });
            })
          );
      })
    );
  });

  uploadRoomplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.uploadRoomplan),
      switchMap(action => {
        return this.locationService
          .uploadDocumentToSpace({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            spaceId: action.roomId,
            body: action.file,
            tab: action.tab,
          })
          .pipe(
            map(document => {
              this.notificationService.showSuccessMessage(`Roomplan was successfully uploaded`);
              return uploadRoomplanSuccess({ document, roomId: action.roomId, floorId: action.floorId });
            }),
            catchError(error => {
              this.notificationService.showErrorMessage(error.message);
              return of(uploadRoomplanError({ error }));
            })
          );
      })
    );
  });

  deleteRoomplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.deleteRoomplan),
      switchMap(action => {
        return this.locationService
          .deleteDocumentFromSpace({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            spaceId: action.roomId,
            filename: action.fileName,
          })
          .pipe(
            map(() => {
              this.notificationService.showSuccessMessage(`Roomplan was successfully deleted`);
              return deleteRoomplanSuccess({
                roomId: action.roomId,
                floorId: action.floorId,
                fileName: action.fileName,
              });
            }),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);
              return deleteRoomplanError({ error });
            })
          );
      })
    );
  });

  getRoomDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.getRoomDocuments),
      switchMap(action => {
        return this.locationService
          .getAllSpaceDocuments({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            spaceId: action.roomId,
          })
          .pipe(
            map(results => results.sort((a, b) => new Date(a.uploadedOn).getTime() - new Date(b.uploadedOn).getTime())),
            map(documents => {
              return getRoomDocumentsSuccess({ floorId: action.floorId, roomId: action.roomId, documents });
            })
          );
      })
    );
  });

  // Floorplan effects
  uploadFloorplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.uploadFloorplan),
      switchMap(action => {
        return this.locationService
          .uploadDocumentToSpace({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            spaceId: action.spaceId,
            body: action.file,
          })
          .pipe(
            map(document => {
              this.notificationService.showSuccessMessage(`Floorplan was successfully uploaded`);
              return uploadFloorplanSuccess({ document, spaceId: action.spaceId });
            }),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);
              return uploadFloorplanError({ error });
            })
          );
      })
    );
  });

  deleteFloorplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.deleteFloorplan),
      switchMap(action => {
        return this.locationService
          .deleteDocumentFromSpace({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            spaceId: action.spaceId,
            filename: action.fileName,
          })
          .pipe(
            map(() => {
              this.notificationService.showSuccessMessage(`Floorplan was successfully deleted`);
              return deleteFloorplanSuccess({ spaceId: action.spaceId });
            }),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);
              return deleteFloorplanError({ error });
            })
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private locationService: LocationService,
    private notificationService: NotificationsService,
    private appService: AppService
  ) {}
}
