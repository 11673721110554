import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { deviceColumnOptions } from '@mocks';
import { DevicesTableV2Component } from '@devices';
import { DeviceTableCols } from '@models';
import { DevicesFilterService, SessionStorageService } from '@services';

@Component({
  selector: 'app-devices-main-page',
  styleUrl: './devices-main-page.component.scss',
  templateUrl: './devices-main-page.component.html',
})
export class DevicesMainPageComponent implements OnDestroy {
  protected devicesFilterService = inject(DevicesFilterService);

  @ViewChild(DevicesTableV2Component)
  protected deviceTable!: DevicesTableV2Component;

  protected readonly TABLE_ID = 'device-main-table';
  private sessionStorageService = inject(SessionStorageService);

  constructor() {
    this.devicesFilterService.initColumns(deviceColumnOptions.map(column => column.value));

    const columns = this.sessionStorageService.getColumns(this.TABLE_ID);

    if (columns) {
      this.devicesFilterService.displayedColumns = columns;
    }
  }
  ngOnDestroy() {
    this.devicesFilterService.resetState();
  }

  protected exportDevices(format: 'json' | 'csv') {
    return this.deviceTable.export(format);
  }

  protected filtersChanged(filters: Record<string, string | string[]>) {
    const columns = filters['columns'];

    if (columns && columns.length !== this.devicesFilterService.displayedColumns.length) {
      this.devicesFilterService.generateTableColumns(filters['columns'] as DeviceTableCols[]);
      this.sessionStorageService.saveColumnsSettingsToSessionStorage(
        this.TABLE_ID,
        this.devicesFilterService.displayedColumns
      );
    }
  }
}
