import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, isLoggedIn, logout } from '@ngrx-store';
import { environment } from '@env/environment';

export const API_VERSION_CONTEXT_TOKEN = new HttpContextToken<string>(() => 'v1');

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = localStorage.getItem('user');
    const accessToken = user && JSON.parse(user)['accessToken'];
    const assetRequest = request.url.includes('/assets/');
    const version = environment.useContextApiVersioning ? request.context.get(API_VERSION_CONTEXT_TOKEN) + '/' : '';

    if (!assetRequest) {
      request = request.clone({
        url: environment.api + version + request.url,
        headers: accessToken ? request.headers.set('SecurityToken', accessToken) : undefined,
      });
    }

    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          // TODO : this code should be removed when the socket logic will be implemented
          const userActive = !!localStorage.getItem('user');
          const syncUrlsIncidents = '/incidents';
          const syncUrlsDevices = '/devices';
          if (event.url?.includes(syncUrlsIncidents || syncUrlsDevices) && !userActive) {
            return event.clone({ ...{ body: { data: [] } } });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store
            .select(isLoggedIn)
            .pipe(take(1))
            .subscribe(isLoggedIn => {
              if (isLoggedIn) {
                this.store.dispatch(logout());
              }
            });
        }
        return throwError({
          name: 'Error',
          message: error.error?.message || error.message,
          status: error.status,
        });
      })
    );
  }
}
